import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import styled from 'styled-components'
import { formatMoney } from 'util/functionUtil'
import { THEME_COLORS } from 'common/theme/_colors'
import { fDateTimeCustom } from 'util/formatTime'
import React, { useContext, useEffect } from 'react'
import { sendRequestAuth } from '../../../../api/api'
import config from '../../../../config'
import { CustomerContext } from '../../provider/_context'
import { customerActions } from '../../provider/_reducer'
import { Skeleton } from '@mui/material'

export const RowTabQRcode = ({ data, ...props }) => {
  const { pageState, pageDispatch } = useContext(CustomerContext)
  const historyDetail = pageState?.table?.qrHistory?.detail?.list
  const qrCodeLoading = pageState?.table?.qrHistory?.detail?.loading
  useEffect(() => {

    pageDispatch({
      type: customerActions.HISTORY_QRCODE_TABLE_DISPLAY_LOADING_UPDATE,
      payload: true,
    })
    const getOrderHistory = async () => {
      const response = await sendRequestAuth('get',
        `${config.API}/sale/customer/detail/${data?.card_code}/scan-logs`
      )
      return response
    }
    const res = getOrderHistory()
    res.then(value => {
      if (!!value.data.success) {
        pageDispatch({
          type: customerActions.UPDATE_QR_HISTORY_TABLE,
          payload: value.data.data,
        })
      }
      pageDispatch({
        type: customerActions.HISTORY_QRCODE_TABLE_DISPLAY_LOADING_UPDATE,
        payload: false,
      })
    })
  }, [])

  return (
    <StyledRowTabPayment {...props}>
      <div className="row-tab-detail__contents" style={{ minHeight: 300 }}>
        <div className="row-tab-detail__table">
          <div className="row-tab-detail__thead">
            <div className="row-tab-detail__tr">
              <div className="row-tab-detail__th">Ngày quét mã</div>
              <div className="row-tab-detail__th">Sale quản lý</div>
              <div className="row-tab-detail__th">Người quét mã</div>
            </div>
          </div>
          <div className="row-tab-detail__tbody">
            {
              qrCodeLoading ? (
                  Array.from(Array(6), (e, i) => (
                    <QrcodePlaceholder key={i}/>
                  ))
                )
                : historyDetail.length > 0
                ? historyDetail.map(item => (
                  <div key={item.id} className="row-tab-detail__tr">
                    <div
                      className="row-tab-detail__td"
                    >
                      {fDateTimeCustom(item?.created_at, { format: 'dd/MM/yyyy HH:mm' }) || '---'}
                    </div>
                    <div className="row-tab-detail__td">
                      {item?.teacher_name || '---'}
                    </div>
                    <div className="row-tab-detail__td">
                      <Text fontWeight={item?.action_type === 'change_teacher' ? 600 : 400}>{item?.user_name || '---'}</Text>
                    </div>
                  </div>
                ))
                : <div className="row-tab-detail__empty flex">
                  <Text as={'p'}>Không tìm thấy lịch sử phân công Sale quản lý</Text>
                </div>
            }
          </div>
        </div>
      </div>
    </StyledRowTabPayment>
  )
}

const QrcodePlaceholder = ({ ...props }) => {
  return (
    <div {...props} className="row-tab-detail__tr" style={{ background: '#fff' }}>
      {Array.from(Array(3), (e, i) => (
        <div key={i} className="row-tab-detail__td" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
            }}
          />
        </div>
      ))}
    </div>
  )
}

const StyledRowTabPayment = styled.div`
  position: relative;
  .row-tab-detail{
    &__empty{
      align-items: center;
      justify-content: center;
      min-height: 300px;
      p{
        width: 100%!important;
        text-align: center;
      }
    }
  }
  .row-tab-detail__content{
    display: flex;
    &-group{
      width: 33.33%;
      h4{
        margin-bottom: 24px;
      }
      &--items{
        margin-bottom: 16px;
        p{
          margin-bottom: 8px;
        }
      }
    }
  }
  .row-tab-payment {
    &__list-item {
      margin-bottom: 12px;
      padding: 12px 24px;

      border-left: 3px solid ${THEME_SEMANTICS.delivered};

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__list-item-toggle {
      display: flex;
      align-items: center;

      cursor: pointer;

      &[data-active='true'] {
        svg {
          transform: rotate(0deg);
        }
      }

      svg {
        margin-left: 8px;

        transform: rotate(180deg);
        transition: transform 0.6s;
      }
    }

    &__list-item-content {
      max-height: 0;

      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      transition: max-height 0.5s;

      &[data-active='true'] {
        max-height: 75vh;
      }
    }

    &__list-item-figure {
      width: 250px;
      margin-top: 8px;
    }
  }
   .row-tab-detail {
    &__table {
      margin-top: 24px;
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      
      &:nth-child(1) {
        width: 25%;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 25%;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 25%;
      }
      &:nth-child(2) {
        flex: 1;

        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 25%;
      }
    }

  }
`
