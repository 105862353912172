import {useEffect, useState} from "react"
import {useContext} from "react"
import {CustomerContext} from '../provider/_context'
import {customerActions} from "../provider/_reducer"
import {useTranslation} from "react-i18next";
import useAlert from '../../../hook/useAlert'
import {sendRequestAuth} from '../../../api/api'
import config from '../../../config'
import {removeAcent} from '../../../common/fieldText/_functions'
import useCustomerFilterForm from "./useCustomerFilterForm";

export const useMoveCustomerModal = () => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(CustomerContext)
   const [animate, setAnimate] = useState(false)
   const {showAlert} = useAlert()
   const {moveCustomer} = pageState
   const {dataSelect} = moveCustomer


   // ===== ===== ===== ===== =====
   // principal
   // ===== ===== ===== ===== =====
   const principalActiveValue = dataSelect.principal.activeValue
   const principalKeyword = dataSelect.principal.keyword
   const principalList = dataSelect.principal.list
   const principalListOrigin = dataSelect.principal.listOrigin
   const principalValue = dataSelect.principal.value

   const handlePrincipalChange = async (data) => {
      pageDispatch({
         type: customerActions.MODAL_PRINCIPAL_UPDATE,
         payload: {value: data},
      })
      pageDispatch({
         type: customerActions.MODAL_TEACHER_UPDATE,
         payload: {value: null},
      })

      pageDispatch({
         type: customerActions.UPDATE_VALIDATE_CUSTOMER_MODAL,
         payload: {principal: ''},
      })
      // get list teacher
      const response = await sendRequestAuth('get', `${config.API}/order/filter/teachers?principal_id=${data?.value}`)
      pageDispatch({
         type: customerActions.MODAL_TEACHER_LIST_UPDATE,
         payload: {
            teacher: {
               list: response.data?.map(item => ({
                  name: item?.last_name + (!!item?.last_name ? " " : '') + item?.name || '',
                  value: item?.id || '',
               })),
            }
         },
      })

   }

   const handlePrincipalKeywordChange = data => {
      const formatDataValue = data?.value
         ? removeAcent(data?.value?.toLowerCase())
         : ''

      const principalListData = principalListOrigin.filter(item => {
         const formatNameItem = item?.name
            ? removeAcent(item.name.toLowerCase())
            : ''
         if (formatNameItem.includes(formatDataValue)) return true
         return false
      })

      pageDispatch({
         type: customerActions.MODAL_PRINCIPAL_KEYWORD_UPDATE,
         payload: {
            keyword: data?.value || '',
            list: principalListData,
         },
      })
   }
   // ===== ===== ===== ===== =====
   // Teacher
   // ===== ===== ===== ===== =====
   const teacherActiveValue = dataSelect.teacher.activeValue
   const teacherKeyword = dataSelect.teacher.keyword
   const teacherList = dataSelect.teacher.list
   const teacherListOrigin = dataSelect.teacher.listOrigin
   const teacherValue = dataSelect.teacher.value

   const handleTeacherChange = data => {
      pageDispatch({
         type: customerActions.MODAL_TEACHER_UPDATE,
         payload: {value: data},
      })

      pageDispatch({
         type: customerActions.UPDATE_VALIDATE_CUSTOMER_MODAL,
         payload: {teacher: ''},
      })
   }

   const handleTeacherKeywordChange = data => {
      const formatDataValue = data?.value
         ? removeAcent(data?.value?.toLowerCase())
         : ''

      const teacherListData = teacherListOrigin.filter(item => {
         const formatNameItem = item?.name
            ? removeAcent(item.name.toLowerCase())
            : ''
         if (formatNameItem.includes(formatDataValue)) return true
         return false
      })

      pageDispatch({
         type: customerActions.MODAL_TEACHER_KEYWORD_UPDATE,
         payload: {
            keyword: data?.value || '',
            list: teacherListData,
         },
      })
   }

   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

   const handleClose = () => {
      if (!pageState.change_modal) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({type: customerActions.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)
         pageDispatch({type: customerActions.DISABLE_SELECT, payload: false})
      } else {

         pageDispatch({type: customerActions.MODAL_CONFIRM, payload: true})
      }
   }
   const checkBeforeSubmit = [
      pageState.valid?.filde_name?.status,
      pageState.valid?.filde_parent?.status,
   ].includes(true)

   const validateModal = () => {
      let valid = 1
      if (!teacherValue?.value) {
         pageDispatch({
            type: customerActions.UPDATE_VALIDATE_CUSTOMER_MODAL, payload: {
               teacher: 'Sale quản lý không được để trống'
            }
         })
         valid = 0
      }
      return valid
   }
   const {functions} = useCustomerFilterForm()
   const handleAccept = async () => {
      if (!validateModal()) return

      const response = await sendRequestAuth('post', `${config.API}/sale/customer/change-teacher/${moveCustomer?.dataCurrent?.card_code}`, {
         "principal_id": principalValue?.value || '',
         "teacher_id": teacherValue?.value || ''
      })
      if (!!response?.data?.success) {
         setTimeout(() => {
            pageDispatch({type: customerActions.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)

         pageDispatch({
            type: customerActions.MODAL_PRINCIPAL_UPDATE,
            payload: {value: null},
         })
         pageDispatch({
            type: customerActions.MODAL_TEACHER_UPDATE,
            payload: {value: null},
         })
         functions.applyCustomerOtherFilter()
         showAlert({
            type: 'success',
            content: response?.data?.message
         })
      } else {
         showAlert({
            type: 'danger',
            content: response?.data?.message
         })
      }

   }

   const handleCancelConfirm = () => {
      pageDispatch({type: customerActions.MODAL_CONFIRM, payload: false})
   }
   const handleAcceptConfirm = () => {
      pageDispatch({type: customerActions.MODAL_CONFIRM, payload: false})
      pageDispatch({type: customerActions.CHANGE_MODAL, payload: false})
      setAnimate(true)
      pageDispatch({
         type: customerActions.MODAL_PRINCIPAL_UPDATE,
         payload: {value: null},
      })
      pageDispatch({
         type: customerActions.MODAL_TEACHER_UPDATE,
         payload: {value: null},
      })
      setTimeout(() => {
         pageDispatch({type: customerActions.OPEN_MODAL, payload: false})
         setAnimate(false)
      }, 300)
      pageDispatch({type: customerActions.DISABLE_SELECT, payload: false})
   }


   return {
      principal: {
         activeValue: principalActiveValue,
         keyword: principalKeyword,
         list: principalList,
         value: principalValue,
         onChange: handlePrincipalChange,
         onKeywordChange: handlePrincipalKeywordChange,
      },
      teacher: {
         activeValue: teacherActiveValue,
         keyword: teacherKeyword,
         list: teacherList,
         listOrigin: teacherListOrigin,
         value: teacherValue,
         onChange: handleTeacherChange,
         onKeywordChange: handleTeacherKeywordChange,
      },
      modal: {
         handleAccept,
         handleClose,
         checkBeforeSubmit,
      },
      confirm: {
         handleCancelConfirm,
         handleAcceptConfirm,
      },
      animate,
   }
}