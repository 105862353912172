import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import useOrderFilterForm from 'Pages/orderBook/hooks/useOrderFilterForm'
import { ORDER_FILTER_TAG_FIELDS } from 'Pages/orderBook/interfaces/_constants'
import { StyledOrderTags } from './_styled'
import { OrderTag } from './_tag'

export const OrderTags = ({ ...props }) => {
  const {
    dateTime,
    paymentMethod,
    // paymentStatus,
    customerType,
    orderType,
    utmSource,
    orderStatus,
    product,
    orderId,
    customerInfo,
    teacher,
    principal,
    functions,
  } = useOrderFilterForm()

  const shouldShowResetAll = functions.hasFilter()

  const handleDeleteAll = () => functions.filterTagDeleteAll()

  return (
    <StyledOrderTags {...props}>

      {orderId?.activeValue && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[0])}
        >
          Mã đơn hàng: {orderId.activeValue}
        </OrderTag>
      )}
      {customerInfo.activeValue && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[1])}
        >
          Tên khách hàng/Số điện thoại: {customerInfo.activeValue}
        </OrderTag>
      )}
      {Array.isArray(product.activeValue) && product.activeValue.length > 0 && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[2])}
        >
          Sản phẩm:{' '}
          {product.activeValue
            .map(item => item?.data?.title || 'unknown')
            .join(', ')}
        </OrderTag>
      )}
      {/* {paymentStatus?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[3])}
        >
          Trạng thái thanh toán: {paymentStatus.activeValue.name}
        </OrderTag>
      )} */}
      {customerType?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[9])}
        >
          Loại khách hàng: {customerType.activeValue.name}
        </OrderTag>
      )}
      {orderType?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[10])}
        >
          Loại đơn hàng: {orderType.activeValue.name}
        </OrderTag>
      )}
      {utmSource?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[11])}
        >
          Nguồn đơn hàng: {utmSource.activeValue.name}
        </OrderTag>
      )}

      {principal?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[4])}
        >
          Hiệu trưởng: {principal.activeValue.name}
        </OrderTag>
      )}
      {teacher?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[5])}
        >
          Giáo viên: {teacher.activeValue.name}
        </OrderTag>
      )}
      {paymentMethod?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[6])}
        >
          Hình thức thanh toán: {paymentMethod.activeValue.name}
        </OrderTag>
      )}
      {orderStatus?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[7])}
        >
          Trạng thái đơn hàng: {orderStatus.activeValue.name}
        </OrderTag>
      )}

      {dateTime?.activeValue?.value && dateTime?.activeValue?.type?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete('dateTime.current')}
        >
          {dateTime.activeValue.type.name}: {dateTime.activeValue.value}
        </OrderTag>
      )}

      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{ marginBottom: 12, cursor: 'pointer' }}
          onClick={handleDeleteAll}
        >
          Đặt lại mặc định
        </Text>
      )}
    </StyledOrderTags>
  )
}
