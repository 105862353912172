import React, {useContext, useEffect} from 'react'
import {StyledDashboardPanels} from './_styled'
import {DashboardContext} from '../../provider/_context'
import {DashboardPanel} from './_dashboardPanel'
import {sendRequestAuth} from 'api/api'
import config from 'config'
import {useDashboardAction} from '../../provider/_reducer'

export const DashboardPanels = ({...props}) => {
   const {pageState, pageDispatch} = useContext(DashboardContext)
   const {panels} = pageState
   useEffect(
      () => {
         pageDispatch({
            type: useDashboardAction.IS_LOADING_PANEL,
            payload: true
         })
         const getOverview = async () => {
            const response = await sendRequestAuth('get', `${config.API}/dashboard/teacher/overview`)
            if (!!response?.data?.success) {
               pageDispatch({
                  type: useDashboardAction.UPDATE_DASHBOARD,
                  payload: response?.data?.data
               })

               pageDispatch({
                  type: useDashboardAction.IS_LOADING_PANEL,
                  payload: false
               })
            }
         }

         getOverview()
      }
      , [])
   return (
      <StyledDashboardPanels {...props}>
         <DashboardPanel
            className="order-panels__item"
            currency=""
            title="Tổng khách hàng"
            value={panels?.total_customers || 0}
         />
         <DashboardPanel
            className="order-panels__item"
            currency="đ"
            title="Tổng doanh thu"
            value={panels?.total_revenue || 0}
         />
      </StyledDashboardPanels>
   )
}
