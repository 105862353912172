import {useEffect, useState} from "react"
import {useContext} from "react"
import {CustomerContext} from '../provider/_context'
import {customerActions, useAddressModalAction, useContactModalAction} from "../provider/_reducer"
import {useTranslation} from "react-i18next";
import useAlert from '../../../hook/useAlert'
import {sendRequestAuth} from '../../../api/api'
import config from '../../../config'
import useCustomerFilterForm from "./useCustomerFilterForm";

export const useContactModal = () => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(CustomerContext)
   const [animate, setAnimate] = useState(false)
   const {showAlert} = useAlert()
   const {contactModal} = pageState

   const handleContactNameChange = data => {
      pageDispatch({
         type: useContactModalAction.UPDATE_MODAL, payload: {
            contactName: data
         }
      })
   }

   const handleContactNameBlur = data => {
      pageDispatch({
         type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
            contactName: data.trim().length === 0 ? "Tên liên hệ không được để trống" : ''
         }
      })
   }

   const handleCardCodeChange = data => {
      pageDispatch({
         type: useContactModalAction.UPDATE_MODAL, payload: {
            cardCode: data
         }
      })
   }

   const handleContactPhoneChange = data => {
      if (data?.length < 12)
         pageDispatch({
            type: useContactModalAction.UPDATE_MODAL, payload: {
               contactPhone: data
            }
         })
   }


   const handleContactPhoneBlur = data => {
      if (data.trim()?.length === 0) {
         pageDispatch({
            type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
               contactPhone: 'Số điện thoại không được để trống'
            }
         })
      } else if (data?.length > 11 || data?.length < 10 && data?.length > 0) pageDispatch({
         type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
            contactPhone: 'Số điện thoại chỉ được phép nhập 10, 11 ký tự.'
         }
      })
      else pageDispatch({
            type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
               contactPhone: ''
            }
         })
   }

   const handleCntctCodeChange = data => {
      pageDispatch({
         type: useContactModalAction.UPDATE_MODAL, payload: {
            cntctCode: data
         }
      })
   }


   const handleCntctCodeBlur = data => {
      pageDispatch({
         type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
            cntctCode: data.trim().length === 0 ? "Mã liên hệ không được để trống" : ''
         }
      })
   }

   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

   const handleClose = () => {
      if (!pageState.contactModal?.change_modal) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({
               type: useContactModalAction.UPDATE_MODAL, payload: {
                  open_modal: false,
                  change_modal: true,
                  customerName: null,
                  cardCode: null,
                  contactName: null,
                  contactPhone: null,
                  cntctCode: null,
                  customerId: null,
                  validate: null,
               }
            })
            setAnimate(false)
         }, 300)
         pageDispatch({type: customerActions.DISABLE_SELECT, payload: false})
      } else {
         pageDispatch({
            type: useContactModalAction.UPDATE_MODAL, payload: {
               modal_confirm: true
            }
         })
      }
   }
   const checkBeforeSubmit = [
      pageState.valid?.filde_name?.status,
      pageState.valid?.filde_parent?.status,
   ].includes(true)

   const validateModal = () => {
      let valid = true
      if (!contactModal?.contactName) {
         valid = false
         pageDispatch({
            type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
               contactName: "Tên liên hệ không được để trống"
            }
         })
      }
      if (contactModal?.contactPhone.trim()?.length === 0) {
         valid = false
         pageDispatch({
            type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
               contactPhone: 'Số điện thoại không được để trống'
            }
         })
      } else if (contactModal?.contactPhone?.length > 11 || contactModal?.contactPhone?.length < 10 && contactModal?.contactPhone?.length > 0) {
         valid = false
         pageDispatch({
            type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
               contactPhone: 'Số điện thoại chỉ được phép nhập 10, 11 ký tự.'
            }
         })
      }
      return valid
   }
   const {functions} = useCustomerFilterForm()
   const handleAccept = async () => {
      if (!validateModal()) return

      pageDispatch({
         type: 'LOADING_PAGE',
         payload: true
      })

      const url = !!contactModal?.contactId ? `${config.API}/sale/customer/contact/update/${contactModal?.contactId}` : `${config.API}/sale/customer/contact/create`
      const data = !!contactModal?.contactId ?
         {
            "contact_name": contactModal?.contactName,
            "contact_phone": contactModal?.contactPhone,
         }
         : {
            "card_code": contactModal?.cardCode,
            "contact_name": contactModal?.contactName,
            "contact_phone": contactModal?.contactPhone,
            // "cntct_code": contactModal?.cntctCode
         }
      const response = await sendRequestAuth('post', url,
         data
      )
      if (!!response?.data?.success) {
         setTimeout(() => {
            pageDispatch({
               type: useContactModalAction.UPDATE_MODAL, payload: {
                  open_modal: false
               }
            })
            setAnimate(false)
         }, 300)
         pageDispatch({
            type: useContactModalAction.UPDATE_MODAL, payload: {
               modal_confirm: false,
               customerId: null,
               change_modal: true,
               cardCode: null,
               contactName: null,
               contactPhone: null,
               cntctCode: null,
               validate: null,
               customerName: null
            }
         })


         // binding data to customer detail
         if (!!contactModal?.contactId) {
            // chỉnh sửa
            // thêm mới insert_id
            let newList = []
            pageState.table.detail.active.contacts.map(add => {
               if (add.id == contactModal?.contactId) {
                  add.contact_name = contactModal?.contactName
                  add.contact_phone = contactModal?.contactPhone
               }
               newList = [
                  ...newList,
                  add
               ]
            })
            pageDispatch({
               type: useContactModalAction.UPDATE_LIST_CONTACT, payload: newList
            })
         } else {
            // thêm mới insert_id
            pageDispatch({
               type: useContactModalAction.UPDATE_LIST_CONTACT, payload: [
                  ...pageState.table.detail.active.contacts,
                  {
                     "card_code": contactModal?.cardCode,
                     "contact_name": contactModal?.contactName,
                     "contact_phone": contactModal?.contactPhone,
                     "cntct_code": contactModal?.cntctCode,
                     'id': response?.data?.insert_id
                  }
               ]
            })

         }

         pageDispatch({
            type: 'LOADING_PAGE',
            payload: false
         })
         showAlert({
            type: 'success',
            content: response?.data?.message
         })
      } else {

         pageDispatch({
            type: 'LOADING_PAGE',
            payload: false
         })
         showAlert({
            type: 'danger',
            content: response?.data?.message
         })
         response?.data?.errors?.forEach((error) => {
               switch (error.field) {
                  case 'contact_phone':
                     pageDispatch({
                        type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           contactPhone: error.message
                        }
                     })
                     break;
                  case 'cntct_code':
                     pageDispatch({
                        type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           cntctCode: error.message
                        }
                     })
                     break;
                  case 'contact_name':
                     pageDispatch({
                        type: useContactModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           contactName: error.message
                        }
                     })
                     break;
                  default:
                     break;
               }
            }
         )
      }

   }

   const handleCancelConfirm = () => {
      pageDispatch({
         type: useContactModalAction.UPDATE_MODAL, payload: {
            modal_confirm: false,
         }
      })
   }

   const handleCancelDeleteConfirm = () => {
      pageDispatch({
         type: useContactModalAction.UPDATE_MODAL, payload: {
            modal_confirm_delete: {
               open: false,
               data: null,
            },
         }
      })
   }

   const handleAcceptDeleteConfirm = async () => {
      pageDispatch({
         type: 'LOADING_PAGE',
         payload: true
      })
      const response = await sendRequestAuth('delete', `${config.API}/sale/customer/contact/delete/${contactModal?.modal_confirm_delete?.data?.id}`,
         ''
      )
      if (!!response?.data?.success) {
         pageDispatch({
            type: useContactModalAction.UPDATE_MODAL, payload: {
               modal_confirm_delete: {
                  open: false,
                  data: null,
               },
            }
         })
         pageDispatch({
            type: useContactModalAction.UPDATE_LIST_CONTACT,
            payload: pageState.table.detail.active.contacts.filter(ct => ct.id !== contactModal?.modal_confirm_delete?.data?.id)
         })

         pageDispatch({
            type: 'LOADING_PAGE',
            payload: false
         })
         showAlert({
            type: 'success',
            content: response?.data?.message
         })
      } else {

         pageDispatch({
            type: 'LOADING_PAGE',
            payload: false
         })
         showAlert({
            type: 'danger',
            content: response?.data?.message
         })
      }


   }


   const handleAcceptConfirm = () => {
      pageDispatch({
         type: useContactModalAction.UPDATE_MODAL, payload: {
            modal_confirm: false,
            customerId: null,
            change_modal: true,
            cardCode: null,
            contactName: null,
            contactPhone: null,
            cntctCode: null,
            validate: null,
            customerName: null,
         }
      })
      setAnimate(true)
      setTimeout(() => {
         pageDispatch({type: useContactModalAction.UPDATE_MODAL, payload: {open_modal: false}})
         setAnimate(false)
      }, 300)
      pageDispatch({type: customerActions.DISABLE_SELECT, payload: false})
   }


   return {
      customerName: contactModal?.customerName,
      contactId: contactModal?.contactId,
      validate: contactModal?.validate,
      contactName: {
         value: contactModal.contactName,
         onChange: handleContactNameChange,
         onBlur: handleContactNameBlur,
      },
      cardCode: {
         value: contactModal.cardCode,
         onChange: handleCardCodeChange
      },
      contactPhone: {
         value: contactModal.contactPhone,
         onChange: handleContactPhoneChange,
         onBlur: handleContactPhoneBlur,
      },
      cntctCode: {
         value: contactModal.cntctCode,
         onChange: handleCntctCodeChange,
         onBlur: handleCntctCodeBlur,
      },
      modal: {
         handleAccept,
         handleClose,
         checkBeforeSubmit,
      },
      confirm: {
         handleCancelConfirm,
         handleAcceptConfirm,
         handleCancelDeleteConfirm,
         handleAcceptDeleteConfirm,
      },
      animate,
   }
}