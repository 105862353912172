import React, {useContext, useEffect} from 'react'
import {TableProductHeader} from './_tableHeader'
import styled from 'styled-components'
import TbodyProduct from './_tableBody'
import FilterForm from './filter'
import {THEME_SEMANTICS} from "../../../../../common/theme/_semantics";
import {THEME_COLORS} from "../../../../../common/theme/_colors";

export const ProductTab = ({data, ...props}) => {

   return (
      <StyledTable>
         <FilterForm/>
         <div className="row-tab-detail__table">
            <TableProductHeader/>
            <div className="row-tab-detail__table-body common-scrollbar">
               <TbodyProduct/>
            </div>
         </div>
      </StyledTable>
   )
}

const StyledTable = styled.div`
position: relative;
.row-tab-detail{
&__empty{
      align-items: center;
      justify-content: center;
      min-height: 300px;
      p{
         width: 100%!important;
         text-align: center;
      }
   }
}
.row-tab-detail__content{
   display: flex;
&-group{
      width: 33.33%;
      h4{
         margin-bottom: 24px;
      }
   &--items{
         margin-bottom: 16px;
         p{
            margin-bottom: 8px;
         }
      }
   }
}
.row-tab-payment {
&__list-item {
      margin-bottom: 12px;
      padding: 12px 24px;

      border-left: 3px solid ${THEME_SEMANTICS.delivered};

   &:last-child {
         margin-bottom: 0;
      }
   }

&__list-item-toggle {
      display: flex;
      align-items: center;

      cursor: pointer;

   &[data-active='true'] {
         svg {
            transform: rotate(0deg);
         }
      }

      svg {
         margin-left: 8px;

         transform: rotate(180deg);
         transition: transform 0.6s;
      }
   }

&__list-item-content {
      max-height: 0;

      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      transition: max-height 0.5s;

   &[data-active='true'] {
         max-height: 75vh;
      }
   }

&__list-item-figure {
      width: 250px;
      margin-top: 8px;
   }
}
.row-tab-detail {
&__table {
      margin-top: 24px;
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
      &-body{
        max-height: 340px;
        overflow: auto;
      }
   }

&__thead {
   .row-tab-detail__tr {
         background: #f7f9fd;
      }
   }

&__tr {
      display: flex;
   }

&__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

   &:nth-child(1) {
         flex: 1;
         padding-left: 32px;
      }
   &:nth-child(2) {
         width: 20%;
         text-align: center;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(3) {
         width: 20%;
         text-align: center;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(4) {
         width: 100px;
      }
   }

&__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

   &:nth-child(1) {
         padding-left: 32px;
         flex: 1;
         display: flex;
         .create-teacher-table__cell-image{
          height: 100px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 16px;
          img{
            height: 100%;
            width: auto;
          }
        }
      }
   &:nth-child(2) {
         width: 20%;
         color: ${THEME_SEMANTICS.delivering};
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(3) {
         width: 20%;
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
      }
   &:nth-child(4) {
         width: 100px;
         display: flex;
         justify-content: center;
         align-items: center;
         cursor: pointer;
      }
   }

}
`
