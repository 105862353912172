import { sendRequestAuth } from 'api/api'
import { removeAcent } from 'common/fieldText/_functions'
import { convertDateTimeToApiFormat } from 'common/form/datePicker/_functions'
import config from 'config'
import { useCallback, useContext } from 'react'
import { DateRangePicker } from 'rsuite'
import { ORDER_FILTER_TAG_FIELDS } from '../interfaces/_constants'
import { OrderContext } from '../provider/_context'
import {formatDateTimeDefaultValue, orderActions} from '../provider/_reducer'
import { debounce } from '@mui/material'
import { orderInitialState } from 'Pages/orderBook/provider/_initState'
import { useSearchParams } from 'react-router-dom'

const useOrderFilterForm = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pageState, pageDispatch } = useContext(OrderContext)
  const { filter, table } = pageState

  // ===== ===== ===== ===== =====
  // SEARCH
  // ===== ===== ===== ===== =====
  const searchValue = filter.search.value
  const querySearch = searchParams.get('search') || ''

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  // ===== ===== ===== ===== =====
  // DATE TIME
  // ===== ===== ===== ===== =====
  const { afterToday } = DateRangePicker
  const dateTimeActiveValue = filter.dateTime.activeValue
  const dateTimeDefaultValue = [filter.dateTime.end,filter.dateTime.start]
  const dateTimeEnd = filter.dateTime.end
  const dateTimeStart = filter.dateTime.start
  const dateTimeType = filter.dateTime.type
  const dateTimeValue = filter.dateTime.value
  const dateTimeTrigger = filter.dateTime.trigger

  const handleDateTimeChange = data =>
    pageDispatch({
      type: orderActions.FILTER_DATE_TIME_UPDATE,
      payload: {
        end: data.value[1],
        start: data.value[0],
        type: data.category,
        value: data.formatValue,
      },
    })

  // ===== ===== ===== ===== =====
  // Payment Method
  // ===== ===== ===== ===== =====
  const paymentMethodActiveValue = filter.paymentMethod.activeValue
  const paymentMethodKeyword = filter.paymentMethod.keyword
  const paymentMethodList = filter.paymentMethod.list
  const paymentMethodListOrigin = filter.paymentMethod.listOrigin
  const paymentMethodValue = filter.paymentMethod.value

  const handlePaymentMethodChange = data =>
    pageDispatch({
      type: orderActions.FILTER_PAYMENT_METHOD_UPDATE,
      payload: { value: data },
    })

  const handlePaymentMethodKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const paymentMethodListData = paymentMethodListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: orderActions.FILTER_PAYMENT_METHOD_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: paymentMethodListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // principal
  // ===== ===== ===== ===== =====
  const principalActiveValue = filter.principal.activeValue
  const principalKeyword = filter.principal.keyword
  const principalList = filter.principal.list
  const principalListOrigin = filter.principal.listOrigin
  const principalValue = filter.principal.value

  const handlePrincipalChange = async (data) => {
    pageDispatch({
      type: orderActions.FILTER_PRINCIPAL_UPDATE,
      payload: { value: data },
    })
    // get list teacher
    const response = await sendRequestAuth('get', `${config.API}/sale/order/filter/teachers?principal_id=${data?.value}`)
    pageDispatch({
      type: orderActions.FILTER_TEACHER_LIST_UPDATE,
      payload: {
        teacher: {
          list: response.data?.map(item => ({
            name: item?.last_name + (!!item?.last_name ? " "  : '' )+ item?.name || '',
            value: item?.id || '',
          })),
        }
      },
    })

  }

  const handlePrincipalKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const principalListData = principalListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: orderActions.FILTER_PRINCIPAL_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: principalListData,
      },
    })
  }
// ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Teacher
  // ===== ===== ===== ===== =====
  const teacherActiveValue = filter.teacher.activeValue
  const teacherKeyword = filter.teacher.keyword
  const teacherList = filter.teacher.list
  const teacherListOrigin = filter.teacher.listOrigin
  const teacherValue = filter.teacher.value

  const handleTeacherChange = data =>
    pageDispatch({
      type: orderActions.FILTER_TEACHER_UPDATE,
      payload: { value: data },
    })

  const handleTeacherKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const teacherListData = teacherListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: orderActions.FILTER_TEACHER_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: teacherListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Payment Status
  // ===== ===== ===== ===== =====
  const paymentStatusActiveValue = filter.paymentStatus.activeValue
  const paymentStatusKeyword = filter.paymentStatus.keyword
  const paymentStatusList = filter.paymentStatus.list
  const paymentStatusListOrigin = filter.paymentStatus.listOrigin
  const paymentStatusValue = filter.paymentStatus.value

  const handleSourceChange = data =>
    pageDispatch({
      type: orderActions.FILTER_SOURCE_UPDATE,
      payload: { value: data },
    })

  const handleSourceKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const paymentStatusListData = paymentStatusListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: orderActions.FILTER_SOURCE_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: paymentStatusListData,
      },
    })
  }

  // ===== ===== ===== ===== =====
  // Customer Type
  // ===== ===== ===== ===== =====
  const customerTypeActiveValue = filter.customerType.activeValue
  const customerTypeKeyword = filter.customerType.keyword
  const customerTypeList = filter.customerType.list
  const customerTypeListOrigin = filter.customerType.listOrigin
  const customerTypeValue = filter.customerType.value

  const handleCustomerTypeChange = data =>
    pageDispatch({
      type: orderActions.FILTER_CUSTOMER_TYPE_UPDATE,
      payload: { value: data },
    })

  const handleCustomerTypeKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const customerTypeListData = customerTypeListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: orderActions.FILTER_CUSTOMER_TYPE_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: customerTypeListData,
      },
    })
  }

  // ===== ===== ===== ===== =====
  // Order Type
  // ===== ===== ===== ===== =====
  const orderTypeActiveValue = filter.orderType.activeValue
  const orderTypeKeyword = filter.orderType.keyword
  const orderTypeList = filter.orderType.list
  const orderTypeListOrigin = filter.orderType.listOrigin
  const orderTypeValue = filter.orderType.value

  const handleOrderTypeChange = data =>
    pageDispatch({
      type: orderActions.FILTER_ORDER_TYPE_UPDATE,
      payload: { value: data },
    })

  const handleOrderTypeKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const orderTypeListData = orderTypeListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: orderActions.FILTER_ORDER_TYPE_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: orderTypeListData,
      },
    })
  }

  // ===== ===== ===== ===== =====
  // Utm Source
  // ===== ===== ===== ===== =====
  const utmSourceActiveValue = filter.utmSource.activeValue
  const utmSourceKeyword = filter.utmSource.keyword
  const utmSourceList = filter.utmSource.list
  const utmSourceListOrigin = filter.utmSource.listOrigin
  const utmSourceValue = filter.utmSource.value

  const handleUtmSourceChange = data =>
    pageDispatch({
      type: orderActions.FILTER_UTM_SOURCE_UPDATE,
      payload: { value: data },
    })

  const handleUtmSourceKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const utmSourceListData = utmSourceListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: orderActions.FILTER_UTM_SOURCE_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: utmSourceListData,
      },
    })
  }

  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // Order Status
  // ===== ===== ===== ===== =====
  const orderStatusActiveValue = filter.orderStatus.activeValue
  const orderStatusKeyword = filter.orderStatus.keyword
  const orderStatusList = filter.orderStatus.list
  const orderStatusListOrigin = filter.orderStatus.listOrigin
  const orderStatusValue = filter.orderStatus.value

  const handleWarehouseChange = data =>
    pageDispatch({
      type: orderActions.FILTER_ORDER_STATUS_UPDATE,
      payload: { value: data },
    })

  const handleWarehouseKeywordChange = data => {
    const formatDataValue = data?.value
      ? removeAcent(data?.value?.toLowerCase())
      : ''

    const orderStatusListData = orderStatusListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(item.name.toLowerCase())
        : ''
      if (formatNameItem.includes(formatDataValue)) return true
      return false
    })

    pageDispatch({
      type: orderActions.FILTER_ORDER_STATUS_KEYWORD_UPDATE,
      payload: {
        keyword: data?.value || '',
        list: orderStatusListData,
      },
    })
  }

  // ===== ===== ===== ===== =====
  // ORDER ID
  // ===== ===== ===== ===== =====
  const orderIdValue = filter.orderId.value
  const orderIdActiveValue = filter.orderId.activeValue

  const handleOrderIdChange = value => {
    if (value === ' ') value = ''
    pageDispatch({
      type: orderActions.FILTER_ORDER_ID_UPDATE,
      payload: { value: value },
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // CUSTOMER INFO
  // ===== ===== ===== ===== =====
  const customerInfoValue = filter.customerInfo.value
  const customerInfoActiveValue = filter.customerInfo.activeValue

  const handleCustomerInfoChange = value => {
    if (value === ' ') value = ''
    pageDispatch({
      type: orderActions.FILTER_CUSTOMER_INFO_UPDATE,
      payload: { value: value },
    })
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

  // ===== ===== ===== ===== =====
  // PRODUCT
  // ===== ===== ===== ===== =====
  const productActiveValue = filter.product.activeValue
  const productKeyword = filter.product.keyword
  const productList = filter.product.list
  const productLoading = filter.product.loading
  const productTab = filter.product.tab
  const productValue = filter.product.value

  const fetchProduct = async keyword => {
    const response = await sendRequestAuth(
      'get',
      `${
        config.API
      }/sale/order/filter/products?keyword=${keyword.trim()}&category_id=&status=&per_page=999&start=`,
    )

    return response
  }

  const handleProductChange = data => {
    const find = productValue.find(item => item.value === data.value)
    const productListData =
      productTab === 'checked'
        ? productValue.filter(item => item.value !== data.value)
        : productList
    const productValueData = find
      ? productValue.filter(item => item.value !== data.value)
      : [...productValue, data]

    pageDispatch({
      type: orderActions.FILTER_PRODUCT_UPDATE,
      payload: {
        list: productListData,
        value: productValueData,
      },
    })
  }

  let productKeywordTimeout
  const handleProductKeywordChange = data => {
    if (productTab === 'all') {
      clearTimeout(productKeywordTimeout)
      productKeywordTimeout = setTimeout(() => {
        pageDispatch({ type: orderActions.FILTER_PRODUCT_ENABLE_LOADING })
        const keyword = data?.value || ''
        if (!!keyword.trim() || keyword === "") {
          const response = fetchProduct(keyword)
          response.then(res => {
            if (res?.status === 200) {
              const productListData = res?.data || []
              pageDispatch({
                type: orderActions.FILTER_PRODUCT_KEYWORD_UPDATE,
                payload: {
                  isFetchNew: true,
                  keyword: keyword,
                  list: productListData.map(item => ({
                    data: item || null,
                    name: item?.title || '',
                    value: item?.id || '',
                  })),
                },
              })
            }
          })
        }
      }, 500)
    } else if (productTab === 'checked') {
      const formatDataValue = data?.value.trim()
        ? removeAcent(data?.value.trim()?.toLowerCase())
        : ''

      const productListData = productValue.filter(item => {
        const formatNameItem = item?.name
          ? removeAcent(item.name.toLowerCase())
          : ''
        if (
          formatNameItem.includes(formatDataValue) &&
          (data.category.value !== ''
            ? item.groups.includes(data.category.value)
            : true)
        )
          return true
        return false
      })

      pageDispatch({
        type: orderActions.FILTER_PRODUCT_KEYWORD_UPDATE,
        payload: {
          keyword: data?.value || '',
          list: productListData,
        },
      })
    }
  }

  const handleProductResetInput = () => {
    pageDispatch({
      type: orderActions.FILTER_PRODUCT_UPDATE,
      payload: {
        list: productList,
        value: [],
      },
    })
  }

  const handleProductTabChange = tab => {
    let productListData = []
    if (tab === 'checked') {
      productListData = productValue

      pageDispatch({
        type: orderActions.FILTER_PRODUCT_TAB_UPDATE,
        payload: { list: productListData, tab },
      })
    } else {
      const response = fetchProduct(productKeyword)
      response.then(res => {
        if (res?.status === 200) {
          const productListData = res?.data || []
          pageDispatch({
            type: orderActions.FILTER_PRODUCT_KEYWORD_UPDATE,
            payload: {
              isFetchNew: true,
              keyword: productKeyword,
              list: productListData.map(item => ({
                data: item || null,
                name: item?.title || '',
                value: item?.id || '',
              })),
              tab,
            },
          })
        }
      })
    }
  }
  // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
  const canSubmitOtherFilter = [
    dateTimeActiveValue.value !== dateTimeValue ||
    JSON.stringify(dateTimeActiveValue.type) !== JSON.stringify(dateTimeType),
    JSON.stringify(paymentMethodActiveValue) !==
    JSON.stringify(paymentMethodValue),
    JSON.stringify(paymentStatusActiveValue) !== JSON.stringify(paymentStatusValue),
    JSON.stringify(customerTypeActiveValue) !== JSON.stringify(customerTypeValue),
    JSON.stringify(orderTypeActiveValue) !== JSON.stringify(orderTypeValue),
    JSON.stringify(utmSourceActiveValue) !== JSON.stringify(utmSourceValue),
    JSON.stringify(orderStatusActiveValue) !== JSON.stringify(orderStatusValue),
    JSON.stringify(productActiveValue) !== JSON.stringify(productValue),
    JSON.stringify(principalActiveValue) !== JSON.stringify(principalValue),
    JSON.stringify(teacherActiveValue) !== JSON.stringify(teacherValue),
    JSON.stringify(orderIdActiveValue) !== JSON.stringify(orderIdValue),
    JSON.stringify(customerInfoActiveValue) !== JSON.stringify(customerInfoValue),
  ].includes(true)

  const queries = {
    date_type: dateTimeActiveValue?.type?.value || '',
    start_date:
      dateTimeActiveValue?.start && dateTimeActiveValue.value
        ? convertDateTimeToApiFormat(dateTimeActiveValue.value.split(' - ')[0])
        : '',
    end_date:
      dateTimeActiveValue?.end && dateTimeActiveValue.value
        ? convertDateTimeToApiFormat(dateTimeActiveValue.value.split(' - ')[1])
        : '',
    product_ids: Array.isArray(productActiveValue)
      ? productActiveValue.map(item => item?.value).join(',')
      : '',
    order_status: orderStatusActiveValue?.value || '',
    payment_status: paymentStatusActiveValue?.value || '',
    customer_type: customerTypeActiveValue?.value || '',
    order_type: orderTypeActiveValue?.value || '',
    utm_source: utmSourceActiveValue?.value || '',
    payment_method: paymentMethodActiveValue?.value || '',
    principal_id: principalActiveValue?.value || '',
    teacher_id: teacherActiveValue?.value || '',
    order_id: orderIdActiveValue || '',
    customer: customerInfoActiveValue|| '',
    per_page: table?.pagination?.amount || 20,
    start: 0,
  }

  const debounceOrderByFilter = useCallback(debounce((keyword, queries) => {
    fetchOrderByFilter(
      { ...queries, keyword: keyword.trim() },
      { forceLoading: true },
    )
  }, 500), [queries])

  const handleSearchChange = e => {
    if (e.target.value === ' ') e.target.value = ''
    const keyword = e.target.value.replace(/\s+/g, ',') || ''
    pageDispatch({
      type: orderActions.FILTER_SEARCH_UPDATE,
      payload: { value: keyword },
    })
    debounceOrderByFilter(keyword.trim().split(' ').join(','), queries)
    // if(keyword.trim().length > 0) debounceOrderByFilter(keyword.trim().split(' ').join(','))
  }

  const applyOrderOtherFilter = async () => {
    const collection = {
      ...queries,
      date_type: dateTimeType?.value || '',
      start_date:
        dateTimeStart && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[0])
          : '',
      end_date:
        dateTimeEnd && dateTimeValue
          ? convertDateTimeToApiFormat(dateTimeValue.split(' - ')[1])
          : '',
      order_status: orderStatusValue?.value || '',
      payment_method: paymentMethodValue?.value || '',
      principal_id: principalValue?.value || '',
      teacher_id: teacherValue?.value || '',
      payment_status: paymentStatusValue?.value || '',
      customer_type: customerTypeValue?.value || '',
      order_type: orderTypeValue?.value || '',
      utm_source: utmSourceValue?.value || '',
      product_ids: Array.isArray(productValue)
        ? productValue.map(item => item?.value).join(',')
        : '',
      customer: customerInfoValue || '',
      order_id: orderIdValue || '',
    }
    fetchOrderByFilter(collection, { forceLoading: true })
  }

  const fetchOrderByFilter = async (qs, opt) => {
    setSearchParams('')
    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: true } } },
      })

    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/sale/order/filter${queryString}&type=1`),
    ])

    if (!!response[0]?.data?.success) {
      pageDispatch({
        type: orderActions.OTHER_FILTER_APPLY,
        payload: {
          display: {
            list: response[0].data.data
          },
          pagination: {
            active: opt?.activePage || 0,
            amount: table.pagination.amount,
            total: response[0]?.data?.meta?.total
              ? Math.ceil(
                response[0]?.data?.meta?.total / table.pagination.amount,
              )
              : 0,
            totalItems: response[0]?.data?.meta?.total || 0,
          },
        },
      })
    }

    if (!!!opt?.notClearDetail)
      pageDispatch({
        type: orderActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: null },
      })

    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })

    pageDispatch({
      type: orderActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: null },
    })
  }

  const fetchOrderByTagDelete = async (qs, opt) => {
    setSearchParams('')
    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: true } } },
      })

    let queryString = '?'
    let i = 0
    for (const [key, value] of Object.entries(qs)) {
      queryString += `${i > 0 ? '&' : ''}${key}=${value}`
      i++
    }

    const response = await Promise.all([
      sendRequestAuth('get', `${config.API}/sale/order/filter${queryString}&type=1`),
    ])

    if (!!response[0]?.data?.success) {
      pageDispatch({
        type: orderActions.DELETE_TAG_UPDATE,
        payload: {
          display: {
            list: response[0].data.data
          },
          pagination: {
            active: opt?.activePage || 0,
            amount: table.pagination.amount,
            total: response[0]?.data?.meta?.total
              ? Math.ceil(
                response[0]?.data?.meta?.total / table.pagination.amount,
              )
              : 0,
            totalItems: response[0]?.data?.meta?.total || 0,
          },
        },
      })
    }

    if (!!!opt?.notClearDetail)
      pageDispatch({
        type: orderActions.TABLE_DISPLAY_DETAIL_UPDATE,
        payload: { active: null },
      })

    if (Number.isNaN(opt?.activePage) || opt?.forceLoading)
      pageDispatch({
        type: orderActions.TABLE_DISPLAY_LOADING_UPDATE,
        payload: { table: { display: { loading: false } } },
      })

    pageDispatch({
      type: orderActions.TABLE_DISPLAY_DETAIL_ID_UPDATE,
      payload: { id: null },
    })
  }
  const filterTagDelete = t => {
    pageDispatch({
      type: orderActions.TAG_FILTER_DELETE,
      payload: { type: t, isSingle: true },
    })

    let tmpCollection = {}
    switch (t) {
      case 'dateTime.current':
        tmpCollection = {
          ...tmpCollection,
          date_type: '',
          start_date: '',
          end_date: '',
        }

        pageDispatch({
          type: orderActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
          payload: { trigger: null },
        })

        break

      case ORDER_FILTER_TAG_FIELDS[8]:
        tmpCollection = {
          ...tmpCollection,
        }
        break

      case ORDER_FILTER_TAG_FIELDS[0]:
        tmpCollection = {
          ...tmpCollection,
          order_id: ''
        }
        break

      case ORDER_FILTER_TAG_FIELDS[1]:
        tmpCollection = { ...tmpCollection, customer: '' }
        break

      case ORDER_FILTER_TAG_FIELDS[2]:
        tmpCollection = { ...tmpCollection, product_ids: '' }
        break

      case ORDER_FILTER_TAG_FIELDS[3]:
        tmpCollection = { ...tmpCollection, payment_status: '' }
        break

      case ORDER_FILTER_TAG_FIELDS[4]:
        tmpCollection = { ...tmpCollection, principal_id: '' }
        break

      case ORDER_FILTER_TAG_FIELDS[5]:
        tmpCollection = { ...tmpCollection, teacher_id: '' }
        break

      case ORDER_FILTER_TAG_FIELDS[6]:
        tmpCollection = { ...tmpCollection, payment_method: '' }
        break

      case ORDER_FILTER_TAG_FIELDS[7]:
        tmpCollection = { ...tmpCollection, order_status: '' }
        break
      case ORDER_FILTER_TAG_FIELDS[9]:
        tmpCollection = { ...tmpCollection, customer_type: '' }
        break
      case ORDER_FILTER_TAG_FIELDS[10]:
        tmpCollection = { ...tmpCollection, order_type: '' }
        break
      case ORDER_FILTER_TAG_FIELDS[11]:
        tmpCollection = { ...tmpCollection, utm_source: '' }
        break
      default:
        break
    }

    const collection = { ...queries, ...tmpCollection }

    fetchOrderByTagDelete(collection, { forceLoading: true })
  }

  const filterTagDeleteAll = isSoft => {
    ORDER_FILTER_TAG_FIELDS.forEach(item =>
      pageDispatch({
        type: orderActions.TAG_FILTER_DELETE,
        payload: { type: item },
      }),
    )

    pageDispatch({
      type: orderActions.FILTER_DATE_TIME_TRIGGER_UPDATE,
      payload: {
        trigger: dateTimeTrigger === null ? true : !dateTimeTrigger,
      },
    })

    if (isSoft) return
    const date = formatDateTimeDefaultValue.split(' - ')

    const collection = {
      ...queries,
      date_type: 'created',
      start_date: '',
      end_date: '',
      user_id: '',
      payment_method: '',
      product_ids: '',
      payment_status: '',
      order_status: '',
      principal_id: '',
      teacher_id: '',
      customer: '',
      order_id:'',
    }

    fetchOrderByFilter(collection, { forceLoading: true })
  }

  return {
    pageState,
    dateTime: {
      activeValue: dateTimeActiveValue,
      defaultValue: dateTimeDefaultValue,
      disabledDate: afterToday(),
      triggerDefault: dateTimeTrigger,
      value: dateTimeValue,
      onChange: handleDateTimeChange,
    },
    product: {
      activeValue: productActiveValue,
      list: productList,
      loading: productLoading,
      tab: productTab,
      value: productValue,
      onChange: handleProductChange,
      onInputReset: handleProductResetInput,
      onKeywordChange: handleProductKeywordChange,
      onTabChange: handleProductTabChange,
    },
    search: {
      value: searchValue,
      onChange: handleSearchChange,
    },
    orderId: {
      value: orderIdValue,
      activeValue: orderIdActiveValue,
      onChange: handleOrderIdChange,
    },
    customerInfo: {
      value: customerInfoValue,
      activeValue: customerInfoActiveValue,
      onChange: handleCustomerInfoChange,
    },
    paymentMethod: {
      activeValue: paymentMethodActiveValue,
      keyword: paymentMethodKeyword,
      list: paymentMethodList,
      value: paymentMethodValue,
      onChange: handlePaymentMethodChange,
      onKeywordChange: handlePaymentMethodKeywordChange,
    },
    principal: {
      activeValue: principalActiveValue,
      keyword: principalKeyword,
      list: principalList,
      value: principalValue,
      onChange: handlePrincipalChange,
      onKeywordChange: handlePrincipalKeywordChange,
    },
    teacher: {
      activeValue: teacherActiveValue,
      keyword: teacherKeyword,
      list: teacherList,
      listOrigin: teacherListOrigin,
      value: teacherValue,
      onChange: handleTeacherChange,
      onKeywordChange: handleTeacherKeywordChange,
    },
    paymentStatus: {
      activeValue: paymentStatusActiveValue,
      keyword: paymentStatusKeyword,
      list: paymentStatusList,
      value: paymentStatusValue,
      onChange: handleSourceChange,
      onKeywordChange: handleSourceKeywordChange,
    },
    customerType: {
      activeValue: customerTypeActiveValue,
      keyword: customerTypeKeyword,
      list: customerTypeList,
      value: customerTypeValue,
      onChange: handleCustomerTypeChange,
      onKeywordChange: handleCustomerTypeKeywordChange,
    },
    orderType: {
      activeValue: orderTypeActiveValue,
      keyword: orderTypeKeyword,
      list: orderTypeList,
      value: orderTypeValue,
      onChange: handleOrderTypeChange,
      onKeywordChange: handleOrderTypeKeywordChange,
    },
    utmSource: {
      activeValue: utmSourceActiveValue,
      keyword: utmSourceKeyword,
      list: utmSourceList,
      value: utmSourceValue,
      onChange: handleUtmSourceChange,
      onKeywordChange: handleUtmSourceKeywordChange,
    },
    orderStatus: {
      activeValue: orderStatusActiveValue,
      keyword: orderStatusKeyword,
      list: orderStatusList,
      value: orderStatusValue,
      onChange: handleWarehouseChange,
      onKeywordChange: handleWarehouseKeywordChange,
    },
    canSubmitOtherFilter,
    queries,
    functions: {
      hasFilter: () => [
        JSON.stringify(dateTimeActiveValue?.value) !==
        JSON.stringify(orderInitialState.filter.dateTime.activeValue?.value),
        !!paymentMethodActiveValue?.name,
        !!paymentStatusActiveValue?.name,
        !!customerTypeActiveValue?.name,
        !!orderTypeActiveValue?.name,
        !!utmSourceActiveValue?.name,
        !!orderStatusActiveValue?.name,
        !!principalActiveValue?.name,
        !!teacherActiveValue?.name,
        !!orderIdActiveValue,
        !!customerInfoActiveValue,
      ].includes(true),
      applyOrderOtherFilter,
      refresh: () =>
        fetchOrderByFilter(
          {
            ...queries,
            type: 1,
            start: table.pagination.active * table.pagination.amount || 0,
          },
          { activePage: table.pagination.active, forceLoading: true },
        ),
      fetchUpdateData: () =>
        fetchOrderByFilter(
          {
            ...queries,
            type: 1,
            start: table.pagination.active * table.pagination.amount,
          },
          { activePage: table.pagination.active, notClearDetail: true },
        ),
      filterTagDelete,
      filterTagDeleteAll,
    },
  }
}

export default useOrderFilterForm
