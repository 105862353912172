import {useEffect, useState} from "react"
import {useContext} from "react"
import {CustomerContext} from '../provider/_context'
import {customerActions, useAddressModalAction, useContactModalAction} from "../provider/_reducer"
import {useTranslation} from "react-i18next";
import useAlert from '../../../hook/useAlert'
import {sendRequestAuth} from '../../../api/api'
import config from '../../../config'
import useCustomerFilterForm from "./useCustomerFilterForm";

export const useAddressModal = () => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(CustomerContext)
   const [animate, setAnimate] = useState(false)
   const {showAlert} = useAlert()
   const {addressModal} = pageState

   const handleAddressNameChange = data => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_MODAL, payload: {
            addressName: data
         }
      })
   }

   const handleAddressNameBlur = data => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_VALIDATE_MODAL, payload: {
            addressName: data.trim().length === 0 ? "Địa chỉ không được để trống" : ''
         }
      })
   }

   const handleCardCodeChange = data => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_MODAL, payload: {
            cardCode: data
         }
      })
   }


   const handleCntctCodeChange = data => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_MODAL, payload: {
            shipCode: data
         }
      })
   }


   const handleCntctCodeBlur = data => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_VALIDATE_MODAL, payload: {
            shipCode: data.trim().length === 0 ? "Mã địa chỉ không được để trống" : ''
         }
      })
   }
   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

   const handleClose = () => {
      if (!pageState.addressModal?.change_modal) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({
               type: useAddressModalAction.UPDATE_MODAL, payload: {
                  open_modal: false,
                  change_modal: true,
                  customerName: null,
                  cardCode: null,
                  addressName: null,
                  validate: null,
                  shipCode: null,
                  customerId: null,

               }
            })
            setAnimate(false)
         }, 300)
         pageDispatch({type: customerActions.DISABLE_SELECT, payload: false})
      } else {
         pageDispatch({
            type: useAddressModalAction.UPDATE_MODAL, payload: {
               modal_confirm: true
            }
         })
      }
   }
   const checkBeforeSubmit = [
      pageState.valid?.filde_name?.status,
      pageState.valid?.filde_parent?.status,
   ].includes(true)

   const validateModal = () => {

      console.log('pageState.table.detail', pageState.table.detail)
      let valid = true
      if (!addressModal?.addressName) {
         valid = false
         pageDispatch({
            type: useAddressModalAction.UPDATE_VALIDATE_MODAL, payload: {
               addressName: "Địa chỉ không được để trống"
            }
         })
      }
      if (!addressModal?.shipCode) {
         valid = false
         pageDispatch({
            type: useAddressModalAction.UPDATE_VALIDATE_MODAL, payload: {
               shipCode: "Mã địa chỉ không được để trống"
            }
         })
      }
      return valid
   }
   const {functions} = useCustomerFilterForm()
   const handleAccept = async () => {
      if (!validateModal()) return

      pageDispatch({
         type: 'LOADING_PAGE',
         payload: true
      })
      const url = !!addressModal?.addressId ? `${config.API}/sale/customer/address/update/${addressModal?.addressId}` : `${config.API}/sale/customer/address/create`
      const data = !!addressModal?.addressId ?
         {
            "address": addressModal?.addressName,
         }
         : {
            "card_code": addressModal?.cardCode,
            "address": addressModal?.addressName,
            "ship_to_code": addressModal?.shipCode
         }
      const response = await sendRequestAuth('post', url,
         data
      )
      if (!!response?.data?.success) {
         setTimeout(() => {
            pageDispatch({
               type: useAddressModalAction.UPDATE_MODAL, payload: {
                  open_modal: false
               }
            })
            setAnimate(false)
         }, 300)
         pageDispatch({
            type: useAddressModalAction.UPDATE_MODAL, payload: {
               modal_confirm: false,
               customerId: null,
               change_modal: true,
               cardCode: null,
               addressName: null,
               shipCode: null,
               validate: null,
               customerName: null
            }
         })
         // binding data to customer detail
         if (!!addressModal?.addressId) {
            // chỉnh sửa
            // thêm mới insert_id
            let newList = []
            pageState.table.detail.active.addresses.map(add => {
               if (add.id == addressModal?.addressId) {
                  add.address = addressModal?.addressName
               }
               newList = [
                  ...newList,
                  add
               ]
            })
            pageDispatch({
               type: useAddressModalAction.UPDATE_LIST_ADDRESS, payload: newList
            })
         } else {
            // thêm mới insert_id
            pageDispatch({
               type: useAddressModalAction.UPDATE_LIST_ADDRESS, payload: [
                  ...pageState.table.detail.active.addresses,
                  {
                     "card_code": addressModal?.cardCode,
                     "address": addressModal?.addressName,
                     "ship_to_code": addressModal?.shipCode,
                     'id': response?.data?.insert_id
                  }
               ]
            })

         }

         pageDispatch({
            type: 'LOADING_PAGE',
            payload: false
         })
         showAlert({
            type: 'success',
            content: response?.data?.message
         })
      } else {

         pageDispatch({
            type: 'LOADING_PAGE',
            payload: false
         })
         showAlert({
            type: 'danger',
            content: response?.data?.message
         })

         response?.data?.errors?.forEach((error) => {
               switch (error.field) {
                  case 'address':
                     pageDispatch({
                        type: useAddressModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           addressName: error.message
                        }
                     })
                     break;
                  case 'ship_to_code':
                     pageDispatch({
                        type: useAddressModalAction.UPDATE_VALIDATE_MODAL, payload: {
                           shipCode: error.message
                        }
                     })
                     break;
                  default:
                     break;
               }
            }
         )
      }

   }

   const handleCancelConfirm = () => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_MODAL, payload: {
            modal_confirm: false,
         }
      })
   }

   const handleCancelDeleteConfirm = () => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_MODAL, payload: {
            modal_confirm_delete: {
               open: false,
               data: null,
            },
         }
      })
   }

   const handleAcceptDeleteConfirm = async () => {

      pageDispatch({
         type: 'LOADING_PAGE',
         payload: true
      })
      const response = await sendRequestAuth('delete', `${config.API}/sale/customer/address/delete/${addressModal?.modal_confirm_delete?.data?.id}`,
         ''
      )
      if (!!response?.data?.success) {
         pageDispatch({
            type: useAddressModalAction.UPDATE_MODAL, payload: {
               modal_confirm_delete: {
                  open: false,
                  data: null,
               },
            }
         })

         pageDispatch({
            type: useAddressModalAction.UPDATE_LIST_ADDRESS,
            payload: pageState.table.detail.active.addresses.filter(add => add.id !== addressModal?.modal_confirm_delete?.data?.id)
         })

         pageDispatch({
            type: 'LOADING_PAGE',
            payload: false
         })
         showAlert({
            type: 'success',
            content: response?.data?.message
         })
      } else {

         pageDispatch({
            type: 'LOADING_PAGE',
            payload: false
         })
         showAlert({
            type: 'danger',
            content: response?.data?.message
         })
      }


   }


   const handleAcceptConfirm = () => {
      pageDispatch({
         type: useAddressModalAction.UPDATE_MODAL, payload: {
            modal_confirm: false,
            customerId: null,
            change_modal: true,
            cardCode: null,
            addressName: null,
            shipCode: null,
            customerName: null,
            validate: null
         }
      })
      setAnimate(true)
      setTimeout(() => {
         pageDispatch({type: useAddressModalAction.UPDATE_MODAL, payload: {open_modal: false}})
         setAnimate(false)
      }, 300)
      pageDispatch({type: customerActions.DISABLE_SELECT, payload: false})
   }


   return {
      customerName: addressModal?.customerName,
      addressId: addressModal?.addressId,
      validate: addressModal?.validate,
      addressName: {
         value: addressModal.addressName,
         onChange: handleAddressNameChange,
         onBlur: handleAddressNameBlur,
      },
      cardCode: {
         value: addressModal.cardCode,
         onChange: handleCardCodeChange
      },
      shipCode: {
         value: addressModal.shipCode,
         onChange: handleCntctCodeChange,
         onBlur: handleCntctCodeBlur,
      },
      modal: {
         handleAccept,
         handleClose,
         checkBeforeSubmit,
      },
      confirm: {
         handleCancelConfirm,
         handleAcceptConfirm,
         handleCancelDeleteConfirm,
         handleAcceptDeleteConfirm,
      },
      animate,
   }
}