import {PATH} from "const/path";

export const DASHBOARD_BREADCUM = [
  {id: 1, name: 'Trang chủ', url: PATH.ADMIN_DASHBOAR},
  // {id: 2, name: 'Khách hàng', url: '#'},
  {id: 2, name: 'Mã QR affiliate', url: '#'}
]

export const TAB_QR_AFFILIATE = [
  {name: 'Danh mục', tab: 'category'},
  // {name: 'Sản phấm', tab: 'product'},
  // {name: 'Sự kiện', tab: 'event'},
]

export const CATEGORY_TABLE_HEADER = [
  {name: 'Danh mục', id: 1},
  {name: 'Danh mục cha', id: 2},
  {name: 'Link giới thiệu', id: 3},
]

export const PRODUCT_TABLE_HEADER = [
  {name: 'Sản phẩm', id: 1},
  {name: 'Link giới thiệu', id: 3},
]
