import { PATH } from '../const/path'
import LoginForm from '../Pages/LoginForm/login'
import ForgotPasswordForm from '../Pages/ForgotPassword'
import NotFound from 'Pages/404/index.'
import NoConnection from 'Pages/noConnection'
import NotPermission from '../Pages/permision/notPermission'
import {
  accountantGroup,
  customerGroup, orderGroup,
  profileGroup,
  statisticalGroup,
  userRoleGroup
} from './groupRouter'
import {DashboardComponent} from "../Pages/dashboard";

const publicRoutes = [
  { path: PATH.HOME, component: LoginForm },
  { path: PATH.LOGIN, component: LoginForm },
  { path: PATH.FORGOT_PASSWORD, component: ForgotPasswordForm },
  { path: PATH.NOT_FOUND, component: NotFound },
  { path: PATH.NO_CONNECTION, component: NoConnection },
  { path: PATH.NOT_PERMISSION, component: NotPermission },
]
const privateRoutes = [
  { path: PATH.ADMIN_DASHBOAR, component: DashboardComponent, prefix: 'publish'},
  ...profileGroup,
  ...userRoleGroup,
  ...customerGroup,
  ...statisticalGroup,
  ...accountantGroup,
  ...orderGroup
]

export { publicRoutes, privateRoutes }
