import React, {memo, useState} from "react";
import styled from "styled-components"
import {DashboardProvider} from "./provider";
import useDashboard from "./hook/useDashboard";
import {DASHBOARD_BREADCUM} from "./interface";
import {PageHeader} from "../../layouts/pageHeader";
import {GridLayout} from "../../layouts/gridLayout";
import {QRCode} from 'react-qrcode-logo';
import useGlobalContext from "../../containerContext/storeContext";
import LogoQr from "./interface/icon.svg"
import {Button} from "../../common/button";
import {saveAs} from 'file-saver';
import {Text} from "../../common/text";
import {GLOBAL_ICONS} from "../../interface/icon";
import {Tooltip} from "../../common/tooltip";
import useAlert from "../../hook/useAlert";
import {DashboardPrincipal} from "./dashboardPrincipal";
import {ShareModal} from "./component/shareModal";
import {DashboardPanels} from "./component/panels";


export const DashboardComponent = memo(() => {
    const {provider, fetch, pagination} = useDashboard()
    const {state, dispatch} = provider

    const [globalState, globalDispatch] = useGlobalContext()
    const {user} = globalState
    const userType = user?.user_type
    if (+userType === 3)
        return (<DashboardProvider value={{pageState: state, pageDispatch: dispatch}}>
            <GridLayout
                header={
                    <PageHeader
                        breadcrumbLinks={DASHBOARD_BREADCUM}
                        breadcrumbTitle={"Dashboard"}
                    />
                }
                grid={
                    [
                        {
                            width: 100,
                            sections: [
                                {
                                    title: '',
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children:  <DashboardPanels/>,
                                    },
                                },
                            ],
                            props: {style: {position: 'relative'}},
                        },
                        {
                            width: 100,
                            sections: [
                                {
                                    title: 'Mã QR giới thiệu',
                                    props: {
                                        style: {
                                            position: 'relative',
                                            padding: 24
                                        },
                                        children: <MyQRCode/>,
                                    },
                                },
                            ],
                            props: {style: {position: 'relative'}},
                        },
                    ]
                }
            />
        </DashboardProvider>)
    return <DashboardPrincipal/>

})

const MyQRCode = () => {
    const [state, dispatch] = useGlobalContext()
    const {user} = state
    const referral = user?.referral?.url
    const {showAlert} = useAlert()
    const handleDownload = () => {
        const element = document.getElementById('react-qrcode-logo');
        const imgData = element.toDataURL('image/png')
        saveAs(imgData, `qr-code__${user?.user_id}.png`);
    };

    const copyContent = () => {
        navigator.clipboard.writeText(user?.referral?.url)
        showAlert({
            type: 'success',
            content: "Đã copy link giới thiệu"
        })
    };

    const [openShare, setOpenShare] = useState(false)
    return (
        <StyleQrCode>
            <div className={'qr-code__content'}>
                <div className={"qr-code__content-left"}>
                    <QRCode value={referral}
                            qrStyle={'dots'} level={'L'}
                            eyeRadius={5}
                            eyeColor={"#002140"}
                            fgColor={"#002140"}
                            logoImage={LogoQr} size={600}
                            logoWidth={150} logoHeight={150}
                    />
                </div>
                <div className={"qr-code__content-right"}>
                    {/*<div className={"qr-code__content-right___item"}>*/}
                    {/*    <Button appearance={"secondary"} icon={GLOBAL_ICONS.share} onClick={() => setOpenShare(true)}>Chia*/}
                    {/*        sẻ</Button>*/}
                    {/*</div>*/}

                    <div className={"qr-code__content-right___item"}>
                        <Button onClick={handleDownload} icon={GLOBAL_ICONS.export}>Tải xuống</Button>
                    </div>
                    <Text as={'p'}>Linh giới thiệu:
                        <Text as={'a'}
                              href={user?.referral?.url}
                              target={'_blank'}
                              color={'#3264FF'}>&nbsp; {user?.referral?.url}</Text>
                        <Tooltip title={"copy link giới thiệu"}
                                 onClick={copyContent}
                                 style={{cursor: 'pointer'}}>
                            &nbsp; &nbsp; {GLOBAL_ICONS.copy04_x}</Tooltip>
                    </Text>
                </div>
            </div>
            {!!openShare && <ShareModal onClose={() => setOpenShare(false)}/>}
        </StyleQrCode>
    );
};

const StyleQrCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  .qr-code{
    &__content{
      display: flex;
      align-items: center;
      &-left{
        width: 200px;
        height: 200px;
        canvas{
          max-width: 100%;
          max-height: 100%;
        }
      }
      &-right{
        display: block;
        margin-left: 24px;
        &___item{
          margin-bottom: 16px;
        }
      }
    }
  }
`

export default MyQRCode;