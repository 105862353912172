import {Skeleton} from '@mui/material'
import {sendRequestAuth} from 'api/api'
import {Checkbox} from 'common/form/checkbox'
import {Text} from 'common/text'
import config from 'config'
import useAlert from 'hook/useAlert'
import {Td} from 'layouts/tableLayout/_td'
import {Tr} from 'layouts/tableLayout/_tr'
import useOrderFilterForm from 'Pages/orderBook/hooks/useOrderFilterForm'
import useOrderRow from 'Pages/orderBook/hooks/useOrderRow'
import {OrderContext} from 'Pages/orderBook/provider/_context'
import {useContext, useState} from 'react'
import {OrderEmpty} from '../orderEmpty'
import {CellCodeOrder} from './_cellCodeOrder'
import {CellCustomer} from './_cellCustomer'
import {CellPayment} from './_cellPayment'
import {CellStatusOrder} from './_cellStatusOrder'
import {ConfirmDeleteModal} from './_confirmDeleteModal'
import {ReportCustomerModal} from './_reportCustomerModal'
import {RowMenuPopover} from './_rowMenuPopover'
import {RowOrderExtra} from './_rowOrderExtra'
import {formatMoney} from '../../../../util/functionUtil'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useEffect} from 'react'
import {Tooltip} from 'common/tooltipv2'
import {PrintOrderDetail} from '../printDetail'
import {fDateTimeDMY, fDateTimeSuffix} from '../../../../util/formatTime'
import {GLOBAL_ICONS} from '../../../../interface/icon'
import {Loading} from '../../../../common/loading'
import {UTM_SOURCE} from "../../interfaces/_constants";
import {ConfirmMoveOrderModal} from "./_confirmMoveOrderModal";
import {fNumber} from "../../../../util/formatNumber";

export const OrderTBody = () => {
   const {pageState} = useContext(OrderContext)
   const {table} = pageState

   const displayList = table.display.list
   const displayLoading = table.display.loading
   const paginationTotalItems = table.pagination.totalItems
   return (
      <>
         {displayLoading ? (
            Array.from(Array(20), (e, i) => (
               <OrderPlaceholder key={i}/>
            ))
         ) : paginationTotalItems > 0 ? (
            displayList.map(item => <OrderTr key={item.id} data={item}/>)
         ) : (
            <OrderEmpty/>
         )}
         <PrintOrderDetail/>
      </>
   )
}

const OrderPlaceholder = ({...props}) => {
   return (
      <Tr {...props} className="order-table__row">
         {Array.from(Array(10), (e, i) => (
            <Td key={i} className="order-table__cell" data-type="td">
               <Skeleton
                  sx={{
                     width: '100%',
                     height: 33,
                     background: 'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
                  }}
               />
            </Td>
         ))}
      </Tr>
   )
}

const OrderTr = ({data, ...props}) => {
   const {showAlert} = useAlert()
   const {pageState, pageDispatch} = useContext(OrderContext)
   const {functions} = useOrderFilterForm()
   const orderRow = useOrderRow(data)
   const {cell, detail, row} = orderRow
   const {codeOrder, payment} = cell

   const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(null)
   const [confirmMoveOrderModal, setConfirmMoveOrderModal] = useState(null)
   const [reportCustomerModalData, setReportCustomerModalData] = useState(null)
   const [isLoading, setIsLoading] = useState(false)
   const navigate = useNavigate()

   const handlePrint = async _ => {
      // in
      setIsLoading(true)
      const response = await sendRequestAuth(
         'post',
         `${config.API}/sale/order/print-detail/${data.id}`,
         {},
      )
      if (!!response.data.url && response.data.url !== '#') {
         let linkPrint = document.createElement('a')
         linkPrint.href = response.data.url
         linkPrint.target = '_blank'
         document.body.appendChild(linkPrint)
         linkPrint.click()
         setIsLoading(false)
      } else {
         setIsLoading(false)
         showAlert({
            content: response?.data?.message,
            type: 'danger',
         })
      }
   }
   const handleEditOrder = _ => navigate(`/order/${data.id}`)
   const handleCopyOrder = async _ => {
      const response = await sendRequestAuth(
         'post',
         `${config.API}/order/copy/${data.id}`,
      )
      if (response?.data?.success) {
         navigate(`/order/copy/${response?.data?.meta?.insert_id}`)
         showAlert({
            type: 'success',
            content: `Sao chép từ đơn hàng [${data.id}] thành công.`
         })
      } else
         showAlert({
            type: 'danger',
            content: `Sao chép từ đơn hàng [${data.id}] thất bại!`
         })
   }

   const handleConfirmOrder = async () => {
      setIsLoading(true)

      const response = await sendRequestAuth(
         'post',
         `${config.API}/order/confirm/${data.id}`,
      )

      if (response?.data?.success) {
         showAlert({content: 'Cập nhật trạng thái thành công', type: 'success'})
         functions.fetchUpdateData()
      } else {
         showAlert({
            content: response?.data?.message,
            type: 'danger',
         })
      }

      setIsLoading(false)
   }

   const handleShippingOrder = async () => {
      setIsLoading(true)

      const response = await sendRequestAuth(
         'post',
         `${config.API}/order/delivery/${data.id}`,
      )

      if (response?.data?.success) {
         showAlert({content: 'Cập nhật trạng thái thành công', type: 'success'})
         functions.fetchUpdateData()
      } else {
         showAlert({
            content: response?.data?.message,
            type: 'danger',
         })
      }

      setIsLoading(false)
   }
   const handleMoveSapOrder = async () => {
      setIsLoading(true)

      const response = await sendRequestAuth(
         'post',
         `${config.API}/order/send-to-sap/${data.id}`,
      )

      if (response?.data?.success) {
         showAlert({content: response?.data?.message || 'Đẩy đơn hàng xuống SAP thành công', type: 'success'})
         functions.fetchUpdateData()
      } else {
         showAlert({
            content: response?.data?.message || 'Đẩy đơn hàng xuống SAP thất bại',
            type: 'danger',
         })

         functions.fetchUpdateData()
      }

      setIsLoading(false)
   }
   const handleCancelOrder = async () => {
      setIsLoading(true)

      const response = await sendRequestAuth(
         'post',
         `${config.API}/order/cancel/${data.id}`,
      )

      if (response?.data?.success) {
         showAlert({content: 'Cập nhật trạng thái thành công', type: 'success'})
         functions.fetchUpdateData()
      } else {
         showAlert({
            content: response?.data?.message,
            type: 'danger',
         })
      }

      setIsLoading(false)
      setConfirmDeleteModalData(null)
   }
   const handleMoveOrder = async () => {
      setIsLoading(true)

      const response = await sendRequestAuth(
         'post',
         `${config.API}/order/cancel/${data.id}`,
      )

      if (response?.data?.success) {
         showAlert({content: 'Cập nhật trạng thái thành công', type: 'success'})
         functions.fetchUpdateData()
      } else {
         showAlert({
            content: response?.data?.message,
            type: 'danger',
         })
      }

      setIsLoading(false)
      setConfirmMoveOrderModal(null)
   }
   const handleCompletedOrder = async () => {
      setIsLoading(true)

      const response = await sendRequestAuth(
         'post',
         `${config.API}/order/completed/${data.id}`,
      )

      if (response?.data?.success) {
         showAlert({content: 'Cập nhật trạng thái thành công', type: 'success'})
         functions.fetchUpdateData()
      } else {
         showAlert({
            content: response?.data?.message,
            type: 'danger',
         })
      }

      setIsLoading(false)
      setConfirmDeleteModalData(null)
   }

   const handleActionApply = action => {
      switch (action) {
         case 'print':
            handlePrint()
            break
         case 'edit':
            handleEditOrder()
            break
         case 'confirm':
            handleConfirmOrder()
            break
         case 'shipping':
            handleShippingOrder()
            break
         case 'copy':
            handleCopyOrder()
            break
         case 'move-sap-order':
            handleMoveSapOrder()
            break

         case 'cancel-order':
            setConfirmDeleteModalData({
               content: (
                  <>
                     <Text as={'p'}>Bạn có chắc chắn muốn hủy đơn hàng #{data.id} không?</Text>
                     <Text as={'p'}>Lưu ý:</Text>
                     <ul>
                        <li>
                           <Text as={'p'}> &bull; Đơn hàng bị huỷ sẽ không thể thay đổi về các trạng thái khác</Text>
                        </li>
                        <li>
                           <Text as={'p'}> &bull; Đối với đơn đã thanh toán, trạng thái thanh toán sẽ được tự động
                              chuyển thành
                              Refund đối với đơn VNPay và Chưa thanh toán đối với đơn COD</Text>
                        </li>
                     </ul>
                  </>
               ),
               title: ' Xác nhận huỷ đơn hàng #' + data.id,
               onClose: () => setConfirmDeleteModalData(null),
               onSubmit: () => handleCancelOrder(),
            })
            break

         case 'move-distributor-order':
            setConfirmMoveOrderModal({
               idOrder: data.id,
               content: '',
               title: 'Chuyển đơn hàng #' + data.id +' qua nhà phân phối',
               onClose: () => setConfirmMoveOrderModal(null),
               onReload: () => functions.fetchUpdateData()
            })
            break
         case 'completed':
            setConfirmDeleteModalData({
               content: (
                  <>
                     <Text as={'p'}> Bạn có chắc chắn muốn cập nhật trạng thái đơn hàng #{data.id} thành Đã giao
                        hàng?</Text>
                     <Text as={'p'}>Lưu ý:</Text>
                     <ul>
                        <li>
                           <Text as={'p'}> &bull; Đơn hàng Đã giao hàng sẽ không thể thay đổi về các trạng thái
                              khác</Text>
                        </li>
                        <li>
                           <Text as={'p'}> &bull; Đối với đơn chưa thanh toán, trạng thái thanh toán sẽ được tự động
                              chuyển thành
                              Đã thanh toán</Text>
                        </li>
                     </ul>
                  </>
               ),
               title: ' Xác nhận Giao hàng thành công đơn hàng #' + data.id,
               onClose: () => setConfirmDeleteModalData(null),
               onSubmit: () => handleCompletedOrder(),
            })
            break

         default:
            break
      }
   }

   const [searchParams] = useSearchParams()
   useEffect(() => {
      const querySearch = searchParams.get('search') || ''

      if (querySearch && pageState?.table?.display?.list?.length === 1)
         row.onToggleDetail()
   }, [])

   return (
      <>
         {isLoading && (
            <Loading/>
         )}
         <Tr
            {...props}
            className="order-table__row"
            extra={
               <RowOrderExtra
                  id={detail?.id}
                  active={row.shouldOpenDetail}
                  data={detail?.active}
                  rowData={orderRow}
               />
            }
            data-active={row.shouldOpenDetail}
            onClick={row.onToggleDetail}
         >
            <Td className="order-table__cell" data-type="td"></Td>
            <Td className="order-table__cell" data-type="td">
               <CellCodeOrder
                  id={data.id}
                  inventory={codeOrder.haveInventory}
                  time={fDateTimeSuffix(data?.created_at)}
               />
            </Td>
            <Td className="order-table__cell" data-type="td">
               <Text>{fNumber(data?.total_quantity || 0)}</Text>
            </Td>
            <Td className="order-table__cell" data-type="td">
               <Text>{formatMoney(data?.total_amount)}</Text>
            </Td>
            <Td className="order-table__cell" data-type="td">
               <Text>{formatMoney(data?.total_discount || 0)}</Text>
            </Td>
            <Td className="order-table__cell" data-type="td">
               <CellCustomer
                  id={data?.customer_id}
                  name={data.customer_name}
                  phone={data.customer_phone}
                  cardCode={data?.card_code}
               />
            </Td>
            <Td className="order-table__cell" data-type="td">
               {UTM_SOURCE.find(item => item.value === data?.utm_source)?.name || '---'}
            </Td>
            <Td className="order-table__cell" data-type="td">
               {
                  data?.order_type === 'sap'
                     ?
                     data?.order_process_status === 'successed'
                        ?
                        <div>
                           <Text as={'p'}>Mã đơn SAP: {data?.order_process_reference}</Text>
                           <Text color={'#00AB56'} as={'p'}>Tạo đơn thành công</Text>
                        </div>
                        :
                        <div>
                           <Text as={'p'}>Mã đơn SAP: --- </Text>
                           <Text color={'#FF9F41'} as={'p'}>Tạo đơn thất bại</Text>
                        </div>
                     :
                     data?.order_type === 'distributor'
                        ?
                        data?.order_process_status === 'successed'
                           ?
                           <div style={{width: '100%'}}>
                             <Tooltip title={data?.order_process_reference} className={'order-table__tooltipV2'}>
                               <Text as={'p'}>Nhà PP: {data?.order_process_reference}</Text>
                             </Tooltip>
                              <Text color={'#00AB56'} as={'p'}>Đã gửi email</Text>
                           </div>
                           :
                           <div>
                              <Text as={'p'}>Nhà PP: --- </Text>
                              <Text color={'#FF9F41'} as={'p'}>Tạo đơn thất bại</Text>
                           </div>
                        : '---'
               }
            </Td>
            <Td className="order-table__cell" data-type="td">
               <CellStatusOrder id={data.order_status}>
                  {data.order_status_name}
               </CellStatusOrder>
            </Td>
            <Td
               className="order-table__cell"
               data-menu="true"
               data-type="td"
               onClick={e => e.stopPropagation()}
            >
               <button
                  className="order-table__detail-toggle"
                  data-active={row.shouldOpenDetail}
                  onClick={row.onToggleDetail}
               >
                  {GLOBAL_ICONS.up}
               </button>
               <RowMenuPopover
                  id={data.id}
                  shippingStatus={data.order_status}
                  dataOrder={data}
                  onActionClick={handleActionApply}
               />
            </Td>
         </Tr>
         {!!confirmDeleteModalData && (
            <ConfirmDeleteModal {...confirmDeleteModalData} />
         )}
         {!!confirmMoveOrderModal && (
            <ConfirmMoveOrderModal {...confirmMoveOrderModal} />
         )}
         {!!reportCustomerModalData && (
            <ReportCustomerModal data={reportCustomerModalData}/>
         )}
      </>
   )
}
