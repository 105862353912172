import UserInformation from '../UserInformation'
import cls from 'clsx'
import css from './index.module.scss'
import * as React from 'react'
import useGlobalContext from '../../containerContext/storeContext'
import { Text } from '../../common/text'
import styled from 'styled-components'

export default function Header () {
  const [state] = useGlobalContext()

  return state.isLogin ? (
    <div className={cls(css.wrapper)}>
      <div className={cls(css.left)}>
        <Text as="a" className={cls(css.linkLogo)} href="/">
          <img className={cls(css.logo)} src={"/img/logo-light.png"} alt="logo"/>
        </Text>
      </div>
      <div className={cls(css.right)}>
        <StyleGuideHeader>
          <div className={'guide-screen-boder-round'}>
            <div
              className={+state?.user?.is_first_login === 1 ? 'guide-screen-boder-header03' : 'guide-screen-boder-round'}>
              <UserInformation/>
            </div>
          </div>
        </StyleGuideHeader>
      </div>
    </div>
  ) : null
}
const StyleGuideHeader = styled.div`
  .guide-screen-boder-round{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .guide-screen-boder-header01{
    border: 1px dashed #FFFFFF;
    padding: 4px;
    text-align: center;
    display: flex;
    margin-right: 12px;
    border-radius: 6px; 
    height: 47px;
    div {
      margin: 0px !important;
    }
  }
  .guide-screen-boder-header02{
    border: 1px dashed #FFFFFF;
    width: 82px;
    height: 47px;
    left: 255px;
    border-radius: 6px;
    display: flex;
    padding-top: 6px;
    span {
          padding-top: 4px;
    }
    margin-right: 12px;
  }
  .guide-screen-boder-header03{
    border: 1px dashed #FFFFFF;
    height: 47px;
    border-radius: 6px;
    display: flex;
    div {
      margin: 6px 7px 7px 2px;
    }
    margin-right: 2rem;
  }
  .guide-screen-boder-header04{
    cursor: pointer;
    height: 32px;
    padding-top: 6px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover{
       background: rgba(43, 184, 169, 0.1);
       .notice-component-popover {
           .popper-notice__toggle  {
           svg {
          color: #1086E9 !important;

          path {
            stroke: #1086E9;
          }
        }
        }
       }
       
    }
  }
  @media screen and (max-width: 1366px) {
    .guide-screen-boder-header01 , .guide-screen-boder-header02, .guide-screen-boder-header03 {
      height: 43px;
    }
  }
`