import React, {useContext, useState} from "react";
import useCustomerFilterForm from "../../hooks/useCustomerFilterForm";
import useAlert from "../../../../hook/useAlert";
import {customerActions, useCreateCustomerModalAction} from "../../provider/_reducer";
// import {BOOK_COLUMN_NAMES} from "../../../customer/interfaces/_constants";
import PopupNotPermission from "../../../permision/popupNotPermission";
import {PageHeader} from "../customerPageHeader";
import {CUSTOMER_BREADCRUMB} from "../../interfaces/_constants";
import {GLOBAL_ICONS} from "../../../../interface/icon";
import {ModalExport} from "../../../../common/modalExport";
import {CustomerContext} from "../../provider/_context";

export const CustomerHeader = () => {
   const [showPermission, setShowPermission] = useState(false)
   const [exportModalData, setExportModalData] = useState(null)
   const {pageState, pageDispatch} = useContext(CustomerContext)
   const {queries, functions} = useCustomerFilterForm()
   const {showAlert} = useAlert()
   const handleExportClick = () => {
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries({
         ...queries,
         per_page: '',
         start: '',
      })) {
         queryString += `${i > 0 ? '&' : ''}${key}=${value}`
         i++
      }

      handleLargeExport(
         {
            ...queries,
            per_page: '',
            start: '',
         }
      )
   }

   const handleLargeExport = (q) => {
      if (pageState.table.pagination.totalItems <= 0) {
         showAlert({type: 'info', content: `Số lượng khách hàng phải lớn hơn 0`})
         return
      }

      setExportModalData({
         data: {
            query: q,
            total: pageState.table.pagination.totalItems,
         },
         onClose: () => setExportModalData(null),
      })
   }



   return (
      <>
         {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
         <PageHeader
            breadcrumbLinks={CUSTOMER_BREADCRUMB}
            breadcrumbTitle="Quản lý khách hàng"
            actions={[
               {
                  name: 'Xuất excel',
                  icon: GLOBAL_ICONS.export,
                  onClick: handleExportClick,
                  appearance: 'secondary',
               },
            ]}
         />
         {!!exportModalData &&
         <ModalExport data={exportModalData} title={'Khách hàng'} api={`/customer/export-customers`}/>}
      </>
   )
}