import {TAB_QR_AFFILIATE} from "../interface";

export const useQrAffiliateInitialState = {
  qrCode: {
    link: null,
    image: null
  },
  tab: {
    list: TAB_QR_AFFILIATE,
    activeTab: TAB_QR_AFFILIATE[0]
  },

  categoryTab: {
    filter: {
      category: {
        list: [],
        listOrigin: [],
        activeValue: null,
        value: null,
        keyword: '',
      },
    },
    table: {
      display: {
        list: [],
        listDefault: [],
        loading: true,
      },
      selected: {
        list: [],
      },
      detail: {
        id: null,
        active: null,
        list: [],
      },
      pagination: {
        active: 0,
        amount: 20,
        total: 0,
        totalItems: 0,
      },
    },
  },
  productTab: {
    filter: {
      search: {
        value: null,
        active: null
      },
      category: {
        list: [],
        listOrigin: [],
        activeValue: null,
        value: null,
        keyword: '',
      },
    },
    table: {
      display: {
        list: [],
        listDefault: [],
        loading: true,
      },
      selected: {
        list: [],
      },
      detail: {
        id: null,
        active: null,
        list: [],
      },
      pagination: {
        active: 0,
        amount: 20,
        total: 0,
        totalItems: 0,
      },
    },
  },
  modal: {
    open: false
  },
  shareModal: {
    open: false,
    referral: null
  },
  loading: false,
  panels: null,
}