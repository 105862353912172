import styled from 'styled-components'

export const StyledDashboardPanels = styled.div`
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  
  justify-content: center;

  .order-panels {
    &__item {
      width: calc(30% - 16px);
      margin: 16px;
    }
  }
`
