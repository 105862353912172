import {THEME_COLORS} from 'common/theme/_colors'
import { GLOBAL_ICONS } from '../../../interface/icon'

export const STUDENT_BREADCRUMB = [
  {id: 1, name: 'Trang chủ', url: '/'},
  // {id: 2, name: 'Đơn hàng', url: '/'},
  {id: 3, name: 'Quản lý khách hàng', url: '/student'},
]

export const ORDER_HISTORY_FILTER_TAG_FIELDS = [
  'orderId',
  'product',
  'paymentStatus',
  'paymentMethod',
  'orderStatus',
  'dateTime',
]
export const STUDENT_PAGE_HEADER_ACTIONS = [
  {
    id: 1,
    name: null,
    appearance: 'secondary',
    icon: GLOBAL_ICONS.repeat,
  },
  // {
  //   id: 2,
  //   name: 'Xuất Excel',
  //   appearance: 'secondary',
  //   icon: GLOBAL_ICONS.download,
  //   type:'dropdown',
  // },
]

export const STUDENT_FILTER_FORM_DATE_TIME_SORT_TYPES = [
  {id: 1, name: 'Ngày đăng ký', value: 'created'},
]

export const STUDENT_FILTER_FORM_PAYMENT_METHOD = [
  {id: 2, name: 'COD - Chuyển khoản', value: 'BANK'},
  {id: 3, name: 'VNPay', value: 'VNPAY'},
]
export const STUDENT_FILTER_FORM_PAYMENT_STATUS = [
  {id: 1, name: 'Chưa thanh toán', value: 'unpaid'},
  {id: 2, name: 'Đã thanh toán', value: 'paid'},
  {id: 3, name: 'Thanh toán thất bại', value: 'fail'},
  {id: 3, name: 'Đã hoàn tiền', value: 'refund'},
]
export const STUDENT_FILTER_FORM_PRODUCT_TYPE = [
  {id: 1, name: 'Đơn hàng sách', value: 'book'},
  {id: 2, name: 'Đơn hàng số', value: 'digital'},
]
export const STUDENT_FILTER_FORM_STUDENT_STATUS = [
  {id: 1, name: 'Chờ xác nhận', value: 'new'},
  {id: 2, name: 'Đang xử lý', value: 'processing'},
  {id: 3, name: 'Đang giao', value: 'delivery'},
  {id: 4, name: 'Hoàn thành', value: 'completed'},
  {id: 5, name: 'Đã hủy', value: 'canceled'},
]

export const STUDENT_STATUS = [
  {name: 'Đang liên kết', value: 'connecting'},
  {name: 'Không liên kết', value: 'unconnected'},
]

export const STUDENT_FILTER_TAG_FIELDS = [
  'studentId',
  'studentInfo',
  'product',
  'paymentStatus',
  'principal',
  'student',
  'paymentMethod',
  'studentStatus',
  'dateTime',
]

export const STUDENT_LIMIT_EXPORT = 5000

export const STUDENT_TABLE_CELL_PAYMENT_TYPES = {
  danger: 'Thanh toán thất bại',
  success: 'Đã thanh toán',
  warning: 'Đã thanh toán một phần',
  waiting: 'Chưa thanh toán',
}

export const STUDENT_TABLE_CELL_SHIPPING_STATUSES = {
  new: {background: '#EBF5FF', color: '#1A94FF'},
  delivery: {background: '#EFFBF4', color: '#33CC70'},
  processing: {background: '#ECF4FE', color: '#0B74E5'},
  completed: {background: '#EBFFF5', color: '#00AB56'},
  canceled: {background: '#FFEBF2', color: '#FC5994'},
  _6: {background: '#EBFFF9', color: '#007B56'},
  _7: {background: '#FFEBEB', color: '#FF7471'},
  _8: {background: '#EBFAFF', color: '#038DB2'},
  _15: {background: '#EFF3FB', color: '#7C88A6'},
  _17: {background: '#FFF0EB', color: '#FC4C0A'},
  _19: {background: '#EBFFF4', color: '#007D3A'},
  _20: {background: '#FFF5EB', color: '#FC820A'},
  _21: {background: '#EFF3FB', color: '#7C88A6'},
  _22: {background: '#EBF8FE', color: '#1BACF9'},
  _23: {background: '#FFEBEC', color: '#FF424E'},
}

export const STUDENT_TABLE_ROW_MENU_POPOVER =
  {
    new: [
      {
        id: 2,
        name: 'In chi tiết khách hàng',
        icon: GLOBAL_ICONS.printer,
        action: 'print',
      },
      {
        id: 3,
        name: 'Hoàn thành khách hàng',
        icon: GLOBAL_ICONS.confirmStudent,
        action: 'confirm',
      },
      {
        id: 6,
        name: 'Hủy khách hàng',
        icon: GLOBAL_ICONS.packageX,
        action: 'cancel-student',
      }
    ],
    processing: [
      {
        id: 2,
        name: 'In chi tiết khách hàng',
        icon: GLOBAL_ICONS.printer,
        action: 'print',
      },
      {
        id: 3,
        name: 'Hoàn thành khách hàng',
        icon: GLOBAL_ICONS.confirmStudent,
        action: 'confirm',
      },
      {
        id: 6,
        name: 'Hủy khách hàng',
        icon: GLOBAL_ICONS.packageX,
        action: 'cancel-student',
      }
    ],
    completed: [
      {
        id: 2,
        name: 'In chi tiết khách hàng',
        icon: GLOBAL_ICONS.printer,
        action: 'print',
      },
      {
        id: 2,
        name: 'Resend Email',
        icon: GLOBAL_ICONS.resendEmail,
        action: 'resend',
      },
    ],
    canceled: [
      {
        id: 2,
        name: 'In chi tiết khách hàng',
        icon: GLOBAL_ICONS.printer,
        action: 'print',
      },
    ]

  }

export const STUDENT_TABLE_ROW_EXTRA_TABS = [
  {id: 1, name: 'Chi tiết khách hàng', value: 'detail'},
  {id: 2, name: 'Lịch sử mua hàng', value: 'order'},
  {id: 3, name: 'Sản phẩm đã mua', value: 'product'},
  {id: 4, name: 'Lịch sử quét QR giới thiệu', value: 'qrcode'},
]


export const STUDENT_TABLE_ROW_EXTRA_TAB_PAYMENT_SUBMIT_MODAL_FIGURES = [
  {id: 1, name: 'Mã khách hàng', color: THEME_COLORS.secondary_100},
  {id: 2, name: 'Giá trị khách hàng', color: THEME_COLORS.secondary_100},
]

export const STUDENT_TABLE_THEAD_SELECTED_ACTIONS = [
  {id: 1, name: 'In chi tiết khách hàng', value: '1'},
]

export const STUDENT_TABLE_THEAD_PAYMENT_FILTER_LIST = [
  {id: 1, name: 'Đã thanh toán', value: 'paid'},
  {id: 2, name: 'Chưa thanh toán', value: 'unpaid'},
  {id: 3, name: 'Thanh toán 1 phần', value: 'partial'},
]

export const EXPORT_STUDENT_EXCEL_SELECTIVE=[
  {id:'stt', name:'STT', position: 0, default:1, status: 1},
  {id:'student_id', name:'Mã khách hàng', position: 1, default:1, status: 1},
  {id:'sku', name:'Mã SKU sản phẩm', position: 2, default:1, status: 1},
  {id:'student_name', name:'Tên khách hàng', position: 3, default:1, status: 1},
  {id:'dt_created', name:'Ngày mua hàng', position: '', default:0, status: 0},
  {id:'total_amount', name:' Giá trị khách hàng ', position: '', default:0, status: 0},
  {id:'shipping_status_name', name:'Trạng thái khách hàng', position: '', default:0, status: 0},
  {id:'studentStatus_name', name:'Kho xuất hàng', position: '', default:0, status: 0},
  {id:'origin_name', name:'Nguồn bán hàng', position: '', default:0, status: 0},
  {id:'student_note', name:'Ghi chú khách hàng', position: '', default:0, status: 0},
  {id:'product_name', name:'Tên sản phẩm', position: '', default:0, status: 0},
  {id:'quantity', name:'Số lượng', position: '', default:0, status: 0},
  {id:'price', name:'Giá bán', position: '', default:0, status: 0},
  {id:'discount', name:'Giảm giá SP', position: '', default:0, status: 0},
  {id:'student_discount', name:'Giảm giá ĐH', position: '', default:0, status: 0},
  {id:'total', name:' Thành tiền ', position: '', default:0, status: 0},
  {id:'student_phone', name:'Số điện thoại', position: '', default:0, status: 0},
  {id:'billcode', name:'Mã vận khách', position: '', default:0, status: 0},
  {id:'dt_send_shipping', name:'Ngày gửi giao hàng', position: '', default:0, status: 0},
  {id:'shipping_name', name:'Đối tác vận chuyển', position: '', default:0, status: 0},
  {id:'address', name:'Địa chỉ', position: '', default:0, status: 0},
  {id:'city_name', name:'Tỉnh/Thành', position: '', default:0, status: 0},
  {id:'district_name', name:'Quận/Huyện', position: '', default:0, status: 0},
  {id:'ward_name', name:'Phường/Xã', position: '', default:0, status: 0},
  {id:'cod', name:'Tiền thu hộ', position: '', default:0, status: 0},
  {id:'payment_method', name:'Phương thức thanh toán', position: '', default:0, status: 0},
  {id:'note', name:'Ghi chú giao hàng', position: '', default:0, status: 0},
  {id:'weight', name:'Trọng lượng (Kg)', position: '', default:0, status: 0},
  {id:'length', name:'Dài (cm)', position: '', default:0, status: 0},
  {id:'width', name:'Rộng (cm)', position: '', default:0, status: 0},
  {id:'height', name:'Cao (cm)', position: '', default:0, status: 0},
  {id:'employee_name', name:'Tên nhân viên', position: '', default:0, status: 0},
  {id:'employee_phone', name:'Số điện thoại', position: '', default:0, status: 0},
];
export const EXPORT_EXCEL_SELECTIVE_ACTIVE=[
  {id:'stt', name:'STT', position: 0, default:1, status: 1},
  {id:'student_id', name:'Mã khách hàng', position: 1, default:1, status: 1},
  {id:'sku', name:'Mã SKU sản phẩm', position: 2, default:1, status: 1},
  {id:'student_name', name:'Tên khách hàng', position: 3, default:1, status: 1},
]
