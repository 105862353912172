import {AlternativeAutoComplete} from 'common/form/autoComplete/_alternativeAutoComplete'
import {Option} from 'common/form/autoComplete/_option'
import React from 'react'
import {useHistoryOrderFilterForm} from "../../../../hooks/useHistoryOrderFilterForm";

export const OrderType = () => {
  const {orderType} = useHistoryOrderFilterForm()

  return (
    <AlternativeAutoComplete
      className="order-filter-form__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'Loại đơn hàng', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 120,
        placeholder: 'Chọn loại đơn hàng',
        readOnly: true,
        value: orderType.value?.name || '',
        onIconClick: () => orderType.onChange(null),
      }}
      bottom={true}
      // menu
      menuProps={{
        empty: orderType.list.length <= 0 ? 'Không tìm thấy loại đơn hàng' : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: 'Tìm kiếm loại đơn hàng',
        value: orderType.keyword,
        onChange: orderType.onKeywordChange,
      }}
      hideSearchBar={true}
    >
      {orderType.list.length > 0 &&
        orderType.list.map(item => (
          <Option
            key={item.value}
            className="order-filter-form__option-text"
            data-active={item.value === orderType.value?.value}
            onClick={() => orderType.onChange(item)}
          >
            {item.name}
          </Option>
        ))}
    </AlternativeAutoComplete>
  )
}
