import React, {useContext, useEffect, useState} from 'react';
import {Box, Modal, Skeleton} from "@mui/material";
import {Button} from "common/button";
import {CustomerContext} from "../../../provider/_context";
import {sendRequestAuth} from "../../../../../api/api";
import config from "../../../../../config";
import styled from 'styled-components'
import {fDateTimeCustom} from "../../../../../util/formatTime";
import {Text} from "../../../../../common/text";
import {THEME_SEMANTICS} from "../../../../../common/theme/_semantics";
import {THEME_COLORS} from "../../../../../common/theme/_colors";

export const OrderModal = ({
                              closeModal,
                              closeBackdropClick = false,
                           }) => {

   const {pageState} = useContext(CustomerContext)
   const {detail} = pageState.table
   const [loading, setLoading] = useState(true)
   const [orders, setOrders] = useState([])

   const getOrderDetails = async () => {

      const response = await sendRequestAuth('get', `${config.API}/sale/customer/detail/${detail?.id}/purchased-products/${pageState.table.productTab.orderModal}/orders`)

      if (response.data.success) {
         setOrders(response.data.data)
         setLoading(false)
      }
   }
   useEffect(() => {
      if (!!pageState.table.productTab.orderModal) {
         getOrderDetails()
      }else{
         setOrders([])
         setLoading(true)
      }

   }, [pageState.table.productTab.orderModal])

   return (
      <>
         <Modal
            open={pageState.table.productTab.orderModal}
            onClose={closeBackdropClick ? false : closeModal}
         >
            <StyleOrderModal>
               <Box className={`orders-popup`}>
                  <div>
                     <div
                        className={`orders-popup__header`}
                     >
                        Lịch sử mua sản phẩm
                     </div>
                     <div
                        className={`orders-popup__body`}
                     >
                        <StyledRowTabPayment>
                           <div className="row-tab-detail__contents" style={{ minHeight: 300 }}>
                              <div className="row-tab-detail__table">
                                 <div className="row-tab-detail__thead">
                                    <div className="row-tab-detail__tr">
                                       <div className="row-tab-detail__th">Ngày tạo đơn</div>
                                       <div className="row-tab-detail__th">Mã đơn hàng</div>
                                    </div>
                                 </div>
                                 <div className="row-tab-detail__tbody common-scrollbar">
                                    {
                                       loading ? (
                                             Array.from(Array(3), (e, i) => (
                                                <QrcodePlaceholder key={i}/>
                                             ))
                                          )
                                          : orders.length > 0
                                          ? orders.map(item => (
                                             <div key={item.id} className="row-tab-detail__tr">
                                                <div
                                                   className="row-tab-detail__td"
                                                >
                                                   {fDateTimeCustom(item?.created_at, { format: 'dd/MM/yyyy HH:mm' }) || '---'}
                                                </div>
                                                <div className="row-tab-detail__td">
                                                   <Text as={'a'} href={`/order/digital?search=${item?.order_id}`} target={'blank'} color={'#0F62F9'}>
                                                      {item?.order_id || '---'}
                                                   </Text>
                                                </div>
                                             </div>
                                          ))
                                          : <div className="row-tab-detail__empty flex">
                                             <Text as={'p'}>Không tìm thấy dữ liệu</Text>
                                          </div>

                                    }
                                 </div>
                              </div>
                           </div>
                        </StyledRowTabPayment>
                     </div>
                     <div
                        className={`orders-popup__footer`}
                     >
                        <Button onClick={closeModal}
                                size={'sm'}
                                className={'orders-popup__dismiss'}
                                style={{marginRight: 8}}
                        >Đóng</Button>
                     </div>
                  </div>
               </Box>
            </StyleOrderModal>
         </Modal>
      </>
   );
}

const QrcodePlaceholder = ({ ...props }) => {
   return (
      <div {...props} className="row-tab-detail__tr" style={{ background: '#fff' }}>
         {Array.from(Array(3), (e, i) => (
            <div key={i} className="row-tab-detail__td" data-type="td">
               <Skeleton
                  sx={{
                     width: '100%',
                     height: 33,
                  }}
               />
            </div>
         ))}
      </div>
   )
}

const StyleOrderModal = styled.div`

  .orders-popup{
      background: white;
       margin: 12rem auto 0 auto;
       padding: 24px;
       line-height: 140%;
       max-width: 30rem;
       height: 408px;
       border-radius: var(--border-radius);
       position: relative;
       &__header{
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 24px;
       }
       &__body{
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 24px;
       }
       &__footer{
        position: absolute;
        bottom: 24px;
        left: calc(50% - 40px);
       }
  }


`

const StyledRowTabPayment = styled.div`
  position: relative;
  .row-tab-detail{
    &__tbody{
      height: 220px;
      overflow: auto;
    }
    &__empty{
      align-items: center;
      justify-content: center;
      min-height: 300px;
      p{
        width: 100%!important;
        text-align: center;
      }
    }
  }
  .row-tab-detail__content{
    display: flex;
    &-group{
      width: 33.33%;
      h4{
        margin-bottom: 24px;
      }
      &--items{
        margin-bottom: 16px;
        p{
          margin-bottom: 8px;
        }
      }
    }
  }
  .row-tab-payment {
    &__list-item {
      margin-bottom: 12px;
      padding: 12px 24px;

      border-left: 3px solid ${THEME_SEMANTICS.delivered};

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__list-item-toggle {
      display: flex;
      align-items: center;

      cursor: pointer;

      &[data-active='true'] {
        svg {
          transform: rotate(0deg);
        }
      }

      svg {
        margin-left: 8px;

        transform: rotate(180deg);
        transition: transform 0.6s;
      }
    }

    &__list-item-content {
      max-height: 0;

      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      transition: max-height 0.5s;

      &[data-active='true'] {
        max-height: 75vh;
      }
    }

    &__list-item-figure {
      width: 250px;
      margin-top: 8px;
    }
  }
   .row-tab-detail {
    &__table {
      margin-top: 24px;
      margin-bottom: 12px;

      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      
      &:nth-child(1) {
        width: 45%;
      }
      &:nth-child(2) {
        flex: 1;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 45%;
      }
      &:nth-child(2) {
        flex: 1;
        color: ${THEME_SEMANTICS.delivering};
        cursor: pointer;
      }
    }

  }
`
