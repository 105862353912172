import { Skeleton } from '@mui/material'
import { Text } from 'common/text'
import config from 'config'
import useAlert from 'hook/useAlert'
import { Td } from 'layouts/tableLayout/_td'
import { Tr } from 'layouts/tableLayout/_tr'
import useCustomerFilterForm from 'Pages/customer/hooks/useCustomerFilterForm'
import useCustomerRow from 'Pages/customer/hooks/useCustomerRow'
import { CustomerContext } from 'Pages/customer/provider/_context'
import React, { useContext, useState } from 'react'
import { CustomerEmpty } from '../customerEmpty'
import { RowCustomerExtra } from './_rowCustomerExtra'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Tooltip as TooltipV2 } from 'common/tooltipv2'
import { Tooltip } from 'common/tooltip'
import { PrintCustomerDetail } from '../printDetail'
import { fDateTimeCustom, fDateTimeDMY } from '../../../../util/formatTime'
import { GLOBAL_ICONS } from '../../../../interface/icon'
import { Loading } from '../../../../common/loading'
import ReactImageFallback from 'react-image-fallback'
import { ChangeTeacherModal } from '../modal'
import {useProductAction} from '../../provider/_reducer'
import {OrderModal} from "./tabProduct/modalOrders";
import {ChangeContactModal} from "../modal/contact";
import {ChangeAddressModal} from "../modal/address";
import {CreateCustomerModal} from "../modal/create";
import {ConfirmDeleteContactModal} from "../modal/contact/confirmDeleteContact";
import {ConfirmDeleteAddressModal} from "../modal/address/confirmDeleteAddress";
import {fNumber} from "../../../../util/formatNumber";
import {formatMoney} from "../../../../util/functionUtil";

export const CustomerTBody = () => {
  const { pageState,pageDispatch } = useContext(CustomerContext)
  const { table } = pageState

  const displayList = table.display.list
  const displayLoading = table.display.loading
  const paginationTotalItems = table.pagination.totalItems
  return (
    <>
      {displayLoading ? (
        Array.from(Array(20), (e, i) => (
          <CustomerPlaceholder key={i}/>
        ))
      ) : paginationTotalItems > 0 ? (
        displayList.map(item => <CustomerTr key={item.id} data={item}/>)
      ) : (
        <CustomerEmpty/>
      )}
      <PrintCustomerDetail/>
      <ChangeTeacherModal/>
      <ChangeContactModal/>
      <CreateCustomerModal/>
      <ConfirmDeleteContactModal/>
      <ConfirmDeleteAddressModal/>
      <ChangeAddressModal/>
      <OrderModal closeModal={() => pageDispatch({type: useProductAction.UPDATE_MODAL_ORDER, payload: false})}/>
      {pageState.loading && <Loading/>}
    </>
  )
}

const CustomerPlaceholder = ({ ...props }) => {
  return (
    <Tr {...props} className="customer-table__row">
      {Array.from(Array(9), (e, i) => (
        <Td key={i} className="customer-table__cell" data-type="td">
          <Skeleton
            sx={{
              width: '100%',
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const CustomerTr = ({ data, ...props }) => {
  const { pageState, pageDispatch } = useContext(CustomerContext)
  const customerRow = useCustomerRow(data)
  const { detail, row } = customerRow

  const [isLoading, setIsLoading] = useState(false)

  const [searchParams] = useSearchParams()
  useEffect(() => {
    const querySearch = searchParams.get('search') || ''

    if (querySearch && pageState?.table?.display?.list?.length === 1)
      row.onToggleDetail()
  }, [])

  return (
    <>
      {isLoading && (
        <Loading/>
      )}
      <Tr
        {...props}
        className="customer-table__row"
        extra={
          <RowCustomerExtra
            id={data?.card_code}
            active={row.shouldOpenDetail}
            data={detail?.active}
            rowData={customerRow}
          />
        }
        data-active={row.shouldOpenDetail}
        onClick={row.onToggleDetail}
      >
        <Td className="customer-table__cell" data-type="td">
          <Td className="principal-table__cell" data-type="td">
            <ReactImageFallback
              src={data?.avatar || '/img/useravatar.jpg'}
              // alt={data?.shopname}
              fallbackImage='/img/useravatar.jpg'
              style={{ width: 36, height: 36, borderRadius: 50, marginRight: 8 }}
            />
            <div>
              <TooltipV2 title={data?.card_name} baseOn={'height'} className='principal-table__cell-tooltip'>
                <Text>{data?.card_name || '-'}</Text>
              </TooltipV2>

              {/*<TooltipV2 title={data?.username} baseOn={'height'} className='principal-table__cell-tooltip'>*/}
              {/*  <Text>{data?.card_code || '---'}</Text>*/}
              {/*</TooltipV2>*/}
            </div>
          </Td>
        </Td>
        <Td className="customer-table__cell" data-type="td">
          <Text>{data?.card_code || '-'}</Text>
        </Td>
        <Td className="customer-table__cell" data-type="td">
          <Text>{data?.auto_sync == 1 ? 'Yes' : 'No'}</Text>
        </Td>
        <Td className="customer-table__cell" data-type="td">
          <Text as={'p'}>{fNumber(data?.total_orders || 0)}</Text>
        </Td>
        <Td className="customer-table__cell" data-type="td">
          <Text as={'p'}>{formatMoney(data?.grand_totals || 0)}</Text>
        </Td>
        <Td className="customer-table__cell" data-type="td">
          <Text as={'p'}>{fDateTimeCustom(data?.assign_at, { format: 'HH:mm:ss' })}</Text>
          <Text as={'p'}>{fDateTimeDMY(data?.assign_at)}</Text>
        </Td>
        <Td className="customer-table__cell" data-type="td">
          <Text as={'p'} className={'customer-table__status'}
                data-status={data?.status === 'active'}>{data?.status_name}</Text>
        </Td>
        <Td
          className="customer-table__cell"
          data-menu="true"
          data-type="td"
          onClick={e => e.stopPropagation()}
        >
          <button
            className="customer-table__detail-toggle"
            data-active={row.shouldOpenDetail}
            onClick={row.onToggleDetail}
          >
            {GLOBAL_ICONS.up}
          </button>
        </Td>
      </Tr>
    </>
  )
}
